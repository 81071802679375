import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, Row, Space, Input, notification } from 'antd';
import { useState, useEffect, useContext } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddTicketModal from "../../components/Tickets/AddTicketModal";
import { fetchApi } from "../../utils/RequestBuilder";
import TicketsKanbanList from "../../components/Tickets/TicketsKanbanList";
import EquipmentFilter from "../../components/Tickets/KanbanTools/EquipmentFilter";
import PersonFilter from "../../components/Tickets/KanbanTools/PersonFilter";
import Sort from "../../components/Tickets/KanbanTools/Sort";
import { GlobalContext } from "../../utils/GlobalContext";
import { useAuth } from 'react-oidc-context';
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";


const { Search } = Input;

function Tickets() {
    

    const navigate = useNavigate()

    //Auth
    const auth = useAuth();
    const [user, setUser] = useState({sub: ""})
    const [hasServiceManagerRole, setRole] = useState(false)
    
    //Initial state
    const [ tickets, setTickets ] = useState([]);
        //Unfiltered Ticket Lists
    const [ openTickets, setOpenTickets] = useState([]);
    const [ progressTickets, setProgressTickets] = useState([]);
    const [ closedTickets, setClosedTickets] = useState([]);
        //Filtered Ticket Lists
    const [ filteredOpenTickets, setFilteredOpenTickets] = useState([]);
    const [ filteredProgressTickets, setFilteredProgressTickets] = useState([]);
    const [ filteredClosedTickets, setFilteredClosedTickets] = useState([]);

    const [ addTicketModalIsOpen, setTicketModalIsOpen ] = useState(0);

    const [ assets, setAssets ] = useState([]);
    const [ assetFilterArray, setAssetFilterArray] = useState([]);

    const [ users, setUsers ] = useState([]);

    const [ sortOrder, setSortOrder] = useState(1);
    const [ searchTerm, setSearchTerm ] = useState("");
    const [ userFilter, setUserFilter ] = useState("");
    const [ userFilterValue, setUserFilterValue ] = useState(undefined);
    const [ equipmentFilter, setEquipmentFilter] = useState("");
    const [ equipmentFilterValue, setEquipmentFilterValue] = useState(undefined);
    //const [ reapplyFilters, setReapplyFilters] = useState(0)

    const  notificationContext  = useContext(GlobalContext);
    const [api, contextHolder] = notification.useNotification();

    //Localisation
    const { t } = useTranslation();

     //Show push notification
     const openPushNotification = (placement, status, desc, title, ticketId, msgOrigin) => {
        api[status]({
            duration: 6.5,
            message: title,
            description:
                <div>
                    <div>
                        <b>{msgOrigin}</b> : {desc}
                    </div>
                    <br/>
                    <div>
                        <Link to={`/tickets/${ticketId}`}>
                            {t('tickets.details.push.link')}
                        </Link>
                    </div>
                </div>
        });
    };
    function showPushNotification(msgString, ticketId, msgSender) {
        let desc = msgString.length > 50 ? `${msgString.substring(0, 50)}...` : msgString
        let title = t('tickets.details.push.title');
        let msgOrigin = msgSender;
        openPushNotification('topRight', 'info', desc, title, ticketId, msgOrigin)
    }

    //Open Modal for ticket creation
    const openTicketModal = () => {
        setTicketModalIsOpen(addTicketModalIsOpen + 1)
    };

    //Fetch tickets from Server
    async function loadTickets() {
        try {
            const jsonData = await fetchApi(auth, "/tickets", auth)
            setTickets(jsonData);
        } catch (error) {
            // Handle error
        }

    }

    //Fetch assets from Server
    async function loadAssets() {
        try {
            const jsonData = await fetchApi(auth, "/assets", auth)
            setAssets(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Fetch users from Server
    async function loadUsers() {
        try {
            const jsonData = await fetchApi(auth, "/users", auth)
            setUsers(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Create asset filter array from fetched assets
    function createAssetFilter (assetList){
        let assetFilterArray = [];
        assetFilterArray = assetList.map((item) => {
            return {
                label: item.name,
                options: item.assets.map((asset) => {
                    return {
                        label: asset.name,
                        value: asset.id
                    };
                }),
            };
        });
        return assetFilterArray
    }

    // Check if asset filter is present as GET parameter
    async function getDefaultAsset() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const assetId = params.get('asset');

        if (assetId != null) {

            assets.forEach((workstation) => {
                workstation.assets.forEach((asset) => {
                    if (asset.id === assetId) {
                        setEquipmentFilter(asset.name)
                        setEquipmentFilterValue(asset.name)
                    }
                })
            })
        }
    }

    // Check if user filter is present as GET parameter
    async function getDefaultUser() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get('user');

        if (userId != null) {
            users.forEach((user) => {
                if (user.id === userId) {
                    setUserFilter(user.name)
                    setUserFilterValue(user.id)
                }
                
            })
        }
    }

    function sortAllTickets() {
        sortTickets(openTickets, sortOrder, 'OPEN');
        sortTickets(progressTickets, sortOrder, 'IN_PROGRESS');
        sortTickets(closedTickets, sortOrder, 'CLOSED');
    }

    function customFilterFunction(inputArray, column) {
        let filteredStatusArray = [...inputArray];
        //Apply equipment filter
        if (equipmentFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) =>
                item.asset.toLowerCase() === equipmentFilter.toLowerCase()
            )
        }
        //Apply user filter
        if (userFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) => {
                return item.assigned_to.toLowerCase() === userFilter.toLowerCase()}
            )
        }
        //Apply text search filter
        if (searchTerm !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) =>
                item.description.toLowerCase().includes(searchTerm.toLowerCase()) || item.title.toLowerCase().includes(searchTerm.toLowerCase())
            )  
        }

        switch (column) {
            case 1:
                setFilteredOpenTickets(filteredStatusArray);
                break;
            case 2:
                setFilteredProgressTickets(filteredStatusArray);
                break;
            case 3:
                setFilteredClosedTickets(filteredStatusArray);
                break;
            default:
                break;
        }
    }

    

    function onSearchFieldChange(event) {
        if (event.target.value !== "") {
            setSearchTerm(event.target.value)
        }
    }

    function onUserFilterChange(value, option) {
        setUserFilter(option?.searchterm ?? '')
        setUserFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('user', value);
        navigate(`?${searchParams.toString()}`);
    }

    function onEquipmentFilterChange(value, option) {
        setEquipmentFilter(option?.label ?? '')
        setEquipmentFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('asset', value);
        navigate(`?${searchParams.toString()}`);
    }

    function sortTickets(ticketList, method, status) {
        let sortedTickets = [...ticketList]
        switch (method) {
            case 1: //Updated at desc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.updated_at);
                    const dateB = new Date(b.updated_at);
        
                    return dateB - dateA
                });
                break;
            case 2: //Updated at asc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.updated_at);
                    const dateB = new Date(b.updated_at);
        
                    return dateA - dateB
                });
                break;
            case 3: //Alphabetically desc
                (sortedTickets || []).sort((a,b) => {
                    return b.title.localeCompare(a.title)
                });
                break;
            case 4: //Alphabetically asc
                (sortedTickets || []).sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });
                break;
            default:
                break;  
        }
        switch (status) {
            case 'OPEN':
                setOpenTickets(sortedTickets);
                setFilteredOpenTickets(sortedTickets);
                break;
            case 'IN_PROGRESS':
                setProgressTickets(sortedTickets);
                setFilteredProgressTickets(sortedTickets);
                break;
            case 'CLOSED':
                setClosedTickets(sortedTickets);
                setFilteredClosedTickets(sortedTickets);
                break;
            default:
                break;
        }
    }

    //Create Array for specific Ticket status
    function getStatusArray(data, status) {
        let statusArray = [];
        if ( data !== undefined) {
            statusArray = (data || []).filter((element) => element.status === status)
        }
        return statusArray;
    }

    function onSortChange(order) {
        setSortOrder(order)
    }

    //Handle Context change
   /*  useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "message_created":
                    if ((notificationContext.payload.participant === "manufacturer" && !hasServiceManagerRole) || (notificationContext.payload.participant === "customer" && hasServiceManagerRole)) {
                        if (notificationContext.sendPush) {
                            showPushNotification(
                                notificationContext.payload.text,
                                notificationContext.relation_id,
                                notificationContext.payload.title
                            );
                            notificationContext.senPush = false;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext]) */

    //Handle Change of any filter
    useEffect(() => {
        customFilterFunction(openTickets, 1)
        customFilterFunction(progressTickets, 2)
        customFilterFunction(closedTickets, 3)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, equipmentFilter, userFilter])

    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            setUser(auth.user.profile)
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
            loadTickets().then((response) => {
                // Put the loading of the assets into a chain to ensure that all tickets are loaded
                // before the assets are set
                // This way we can avoid bad affects on the functions that are triggered then the assets state is changed
                loadAssets()
            });
            loadUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    //Set Lists for ticket statuses
    useEffect(() => {
        setOpenTickets(() => getStatusArray(tickets, 'OPEN'));
        setProgressTickets(() => getStatusArray(tickets, 'IN_PROGRESS'));
        setClosedTickets(() => getStatusArray(tickets, 'CLOSED'));

        setFilteredOpenTickets(() => getStatusArray(tickets, 'OPEN'));
        setFilteredProgressTickets(() => getStatusArray(tickets, 'IN_PROGRESS'));
        setFilteredClosedTickets(() => getStatusArray(tickets, 'CLOSED'));

        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tickets])

    //Handle Change of asset list
    useEffect(() => {
        setAssetFilterArray(createAssetFilter(assets))
        getDefaultAsset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets])

    //Handle Change of user list
    useEffect(() => {
        getDefaultUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    //Handle change of sorter method
    useEffect(() => {
        sortAllTickets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder])

    return (
        <>
            {contextHolder}
            <div>
                <div id="sub-header"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >   <Space>
                        <h2>{t('tickets.overview.header')}</h2>
                        <Button
                            onClick={() => openTicketModal()}
                            type="primary">
                                <PlusOutlined />
                                {t('tickets.overview.add')}
                        </Button>
                    </Space>
                    
                    <Space>
                        <EquipmentFilter
                            value={equipmentFilterValue}
                            onChange={onEquipmentFilterChange}
                            options={assetFilterArray}
                        />
                        <PersonFilter
                            value={userFilterValue}
                            onChange={onUserFilterChange}
                            users={users}
                        />
                        <Sort
                            setSortOrder={onSortChange}
                        />
                        <Search
                            style={{
                                width: 200,
                            }}
                            placeholder={t('tickets.overview.filter.search')}
                            onChange={onSearchFieldChange} 
                            enterButton 
                        />
                    </Space>
                </div>
                <div id="sub-content">
                    <Row className="tickets-kanban" gutter={16}>
                        <TicketsKanbanList
                            tickets={filteredOpenTickets}
                            header={t('tickets.status.open')}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                        <TicketsKanbanList
                            tickets={filteredProgressTickets}
                            header={t('tickets.status.in-progress')}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                        <TicketsKanbanList
                            tickets={filteredClosedTickets}
                            header={t('tickets.status.closed')}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                    </Row>
                </div>
                
                <AddTicketModal 
                    isOpen={addTicketModalIsOpen} 
                    assets={assets} 
                    userList={users} 
                    loggedInUser={user.sub} 
                    auth={auth} 
                    callbackFct={() => {
                        if (userFilterValue !== undefined && equipmentFilterValue !== undefined) {
                            window.location.reload()
                        } else {
                            loadTickets();
                        }
                    }}/>
            </div>
        </>
    );
}

export default Tickets;
