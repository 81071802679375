import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Form, Input, Modal, notification } from "antd";
import { payloadApi} from "../../../utils/RequestBuilder";

function EditModuleModal({auth, callbackFct, isOpen, id, labels}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            handleUpdate(auth, createPayload(formValues))
            setIsModalOpen(false)
            form.resetFields();
        })
    };

    //Show Module Update Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
        message: title,
        description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    function showUpdateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response === 200) {
            title = t('administration.modules.form.edit.edit-message.title.success');
            desc = t('administration.modules.form.edit.edit-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('administration.modules.form.edit.edit-message.title.error');
            desc = t('administration.modules.form.edit.edit-message.body.error');
            errorCode = '[' + t('administration.modules.form.edit.edit-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    function createPayload(formData) {
        return {
            "en": formData.label_en,
            "de": formData.label_de,
            "es": formData.label_es,
            "pt": formData.label_pt,
            "fr": formData.label_fr,
            "ch": formData.label_ch,
        }
    }

    async function handleUpdate(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/modules/" + id,
                "PUT",
                payload
            );
            callbackFct()
            showUpdateAlert(result.status)
        } catch (error) {
            console.log(error)
        }
    }

    const onFinish = (values) => {
        form.resetFields();
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields();
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        form.setFieldsValue({
            label_en: labels["en"],
            label_de: labels["de"],
            label_es: labels["es"],
            label_pt: labels["pt"],
            label_fr: labels["fr"],
            label_ch: labels["ch"],
        })
        // eslint-disable-next-line
    }, [isOpen, form])


    return (
        <>
            {contextHolder}
            <Modal
                title={t('administration.modules.form.update')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    initialValues={{
                        label_en: labels["en"],
                        label_de: labels["de"],
                        label_es: labels["es"],
                        label_pt: labels["pt"],
                        label_fr: labels["fr"],
                        label_ch: labels["ch"],
                    }}
                >
                    <Form.Item
                        label={t('administration.languages.en')}
                        name="label_en"
                        rules={[{
                            required: true,
                            message: t('administration.languages.en') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input placeholder={t('administration.languages.placeholder.en')} />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.de')}
                        name="label_de"
                    >
                        <Input  placeholder={t('administration.languages.placeholder.de')}/>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.es')}
                        name="label_es"
                    >
                        <Input  placeholder={t('administration.languages.placeholder.es')}/>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.pt')}
                        name="label_pt"
                    >
                        <Input  placeholder={t('administration.languages.placeholder.pt')}/>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.fr')}
                        name="label_fr"
                    >
                        <Input  placeholder={t('administration.languages.placeholder.fr')}/>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.languages.ch')}
                        name="label_ch"
                    >
                        <Input  placeholder={t('administration.languages.placeholder.ch')}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EditModuleModal