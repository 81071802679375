import { useTranslation } from 'react-i18next';
import { Col, Row, notification } from 'antd';
import NavMenu from "../../components/Assets/NavMenu";
import WorkstationDetails from "../../components/Assets/WorkstationDetails";
import AssetDetails from "../../components/Assets/AssetDetails";
import { fetchApi } from "../../utils/RequestBuilder";
import {useState, useEffect} from "react";
import { useAuth } from 'react-oidc-context';
import Scrollbars from "react-custom-scrollbars-2";

function Assets() {

    //Auth
    const auth = useAuth();

    const [assetList, setAssetList] = useState([])
    const { t } = useTranslation();

    const [asset, setAsset] = useState({selectId: "", name: "", internal_identifier: "", id: "", position: "", type: "", manufacturer: "", equipment_number: ""});
    const [workstation, setWorkstation] = useState({selectId: "", name: "", internal_identifier: "", id: "", position: ""});
    const [workstationSelected, setWorkstationSelected] = useState(true);
    const [menuItemKey, setMenuItemKey] = useState(["001"]);

    const [api, contextHolder] = notification.useNotification();
    const [forceRerender, setForceRerender] = useState(0);
    const [hashValue, setHashValue] = useState("");

    useEffect(() => {
       loadAssets();
    }, [forceRerender]);

    useEffect(() => {
        if (hashValue !=="") {
            let matchingEntry = null
            assetList.forEach(wsEntry => {
                if (wsEntry.id === hashValue) {
                    matchingEntry = wsEntry
                } else {
                    const assetEntry = wsEntry.assets.find(assetEntry => assetEntry.id === hashValue)
                    if (assetEntry) {
                        matchingEntry = assetEntry
                    }
                }
            })
        }
        // eslint-disable-next-line
     }, [hashValue]);

    //Fetch assets from Server
    async function loadAssets() {
        try {
            const jsonData = await fetchApi(auth, "/assets")
            setAssetList(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    useEffect(() => {
        if (window.location.hash && assetList.length !== 0) {
            setHashValue(window.location.hash.substring(1));
        }
    }, [assetList]);

    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            loadAssets();
        }
        // eslint-disable-next-line
    }, [auth])

    const setSelection = (selectId, name, selectType, internal_identifier, id, position, type, type_id, manufacturer, equipment_number) => {
        if (selectType === "workstation") {
            setWorkstation({
                selectId: selectId,
                name: name,
                internal_identifier: internal_identifier,
                id: id,
                position: position
            })
            setAsset({
                selectId: "",
                name: "",
                internal_identifier: "",
                id: "",
                position: "",
                type: "",
                type_id: "",
                manufacturer: "",
                equipment_number: ""
            })
            setWorkstationSelected(true);
            window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+ id
        } else {
            setAsset({
                selectId: selectId,
                name: name,
                internal_identifier: internal_identifier,
                id: id,
                position: position,
                type: type,
                type_id: type_id,
                manufacturer: manufacturer,
                equipment_number: equipment_number
            })
            setWorkstationSelected(false);
            window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+ id
        }
    }

    return (
        <>
            {contextHolder}
            <div>
                <Row>
                    <Col span={4} >
                        <h1>{t('assets.overview.header')}</h1>
                    </Col>
                    <Col span={20} style={{display: "flex"}}>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <div>
                            <Scrollbars
                                autohide="true"
                                style={{minHeight: "80vh"}}
                            >
                                <NavMenu 
                                    setSelection={setSelection} 
                                    assetList={assetList}
                                    menuItemKey={menuItemKey}
                                    hashValue={hashValue}
                                />
                            </Scrollbars>
                        </div>
                    </Col>
                    <Col span={20}>
                        {
                            workstationSelected ?
                                <WorkstationDetails
                                    selectId={workstation.selectId} 
                                    name={workstation.name} 
                                    setSelection={setSelection} 
                                    auth={auth}
                                    id={workstation.id}
                                    assets={assetList} 
                                    callbackFct={loadAssets}
                                    setMenuItemKey={setMenuItemKey}
                                    menuItemKey={menuItemKey}
                                    setForceRerender={setForceRerender}
                                />
                                : 
                                <AssetDetails 
                                    selectId={asset.selectId} 
                                    name={asset.name} 
                                    auth={auth}
                                    id={asset.id} 
                                    type={asset.type} 
                                    type_id={asset.type_id}
                                    manufacturer={asset.manufacturer}
                                    equipment_number={asset.equipment_number}
                                    callbackFct={loadAssets}
                                    setMenuItemKey={setMenuItemKey} 
                                    assets={assetList}
                                    setForceRerender={setForceRerender}
                                />
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Assets;
