import {
    red,
    volcano,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta,
    grey,
  } from '@ant-design/colors';


//Get matching date format fpr selected Language
export function getDateFormat(lng)  {
    switch(lng) {
      case 'en':
        return 'YYYY/MM/DD hh:mm:ss a';
      case 'de':
        return 'DD.MM.YYYY HH:mm:ss';
      default:
        return 'YYYY/MM/DD hh:mm:ss a';
    }
  }


//Get Tag color fpr Priority Tag
export function getPrioTagColor(priority) {
    switch(priority) {
        case 'MINOR':
            return 'green';
        case 'MAJOR': 
            return 'orange';
        case 'CRITICAL':
            return 'red';
        default:
            return 'blue';
    }
}

//Get Avatar color based on first character of signature
export function getAvatarColor(signature) {
    let x = (signature || []).slice(0,1);
    switch(x) {
        case 'A':
        case 'F': 
        case 'M':
            return "#6BA4B8";  //blue gray
        case 'Q':
        case 'Ü':
        case 'T':
            return '#7A9A01'; //green
        case 'O':
        case 'S':
            return '00587C'; //teal
        case 'L':
        case 'B':
        case 'Ä':
            return '#8C4799'; //purple
        case 'U':
        case 'C':
            return '#500778'; //dark purple
        case 'R':
        case 'P': 
        case 'X':
            return '#F2A900'; //warm yellow
        case 'V':
        case 'K':
            return '971B2F'; //burgundy
        case 'H':
        case 'N':
        case 'G':
            return '#C6AA76'; //sand
        case 'J':
        case 'W':
            return '5C462B'; //earth
        case 'Z':
        case 'Ö':
            return '#CE0037'; //red
        case 'I':
        case 'D':
        case 'Y':
            return '#00B5E2'; //bright blue
        

        default: return 'grey'
    }
}
    export function transformToSelectablesUtils(originalData) {
        const selectables = {};
      
        originalData.forEach((workstation) => {
          const workstationKey = workstation.position.toString().padStart(3, '0');
          const workstationName = workstation.name;
          const workstationInternalId = workstation.internal_identifier;
          const workstationId = workstation.id;
          const workstationPosition = workstation.position;
      
          selectables[workstationKey] = {
            type: "workstation",
            name: workstationName,
            internal_identifier: workstationInternalId,
            id: workstationId,
            position: workstationPosition,
          };
      
          if (workstation.assets) {
            workstation.assets.forEach((asset) => {
                selectables[`${workstationKey}.${asset.position.toString().padStart(1, '0')}`] = {
                    name: asset.name,
                    internal_identifier: asset.internal_identifier,
                    id: asset.id,
                    position: asset.position,
                    type: asset.type,
                    type_id: asset.type_id,
                    manufacturer: asset.manufacturer,
                    equipment_number: asset.equipment_number
                };
            });
          }
        });

        return selectables;
      }
    
