import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import EditIssueModal from "./EditIssueModal";
import { getApi } from "../../../utils/RequestBuilder";
import AddIssueModal from "./AddIssueModal";


function  IssuesOverview(auth) {
    const { t } = useTranslation();

    const [addModalIsOpen, setAddModalIsOpen] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(0);
    const [id, setId] = useState(0)
    const [labels, setLabels] = useState({
        en: "englisch",
        de: "deutsch",
        es: "spanisch",
        pt: "portugisisch",
        fr: "französisch",
        ch: "chinesisch",
    });
    const [issues, setIssues] = useState([])

    const columns = [
        {
            title: t('administration.languages.en'),
            dataIndex: 'en',
            key: 'english',
        },
        {
            title: t('administration.languages.de'),
            dataIndex: 'de',
            key: 'german',
        },
        {
            title: t('administration.languages.es'),
            dataIndex: 'es',
            key: 'spanish',
        },
        {
            title: t('administration.languages.pt'),
            dataIndex: 'pt',
            key: 'portuguese',
        },
        {
            title: t('administration.languages.fr'),
            dataIndex: 'fr',
            key: 'french',
        },
        {
            title: t('administration.languages.ch'),
            dataIndex: 'ch',
            key: 'chinese',
        },
        {
            title: t('administration.action-header'),
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Button className="notification-btn-update" onClick={() => setEditValues(record)}>
                    {t('administration.actions.update')}
                </Button>
            )
        }
    ];

    async function loadIssues() {
        try {
            const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/issues");
            let tmpIssues = []
            result.forEach((issue) => {
                let tmpIssue = issue
                tmpIssue.key = issue.id
                tmpIssues.push(tmpIssue)
            })
            setIssues(tmpIssues)
        } catch (error) {
            console.log(error)
        }
    }

    const openAddModal = () => {
        setAddModalIsOpen(addModalIsOpen + 1)
    };

    const openEditModal = () => {
        setEditModalIsOpen(editModalIsOpen + 1)
    };

    const setEditValues = (record) => {
        setLabels({
            en: record.en,
            de: record.de,
            es: record.es,
            pt: record.pt,
            fr: record.fr,
            ch: record.ch,
        })
        setId(record.id)
    }

    useEffect(() => {
        if (id > 0) {
            openEditModal()
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        loadIssues()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Button
                type="primary"
                onClick={() => openAddModal()}
                style={{marginTop: "4px", marginBottom: "12px"}}
            >
                    <PlusOutlined />{t('administration.issues.form.add')}
            </Button>
            <Table
                dataSource={issues}
                columns={columns}
            />
            <AddIssueModal auth={auth} callbackFct={loadIssues} isOpen={addModalIsOpen}/>
            <EditIssueModal auth={auth} callbackFct={loadIssues} isOpen={editModalIsOpen} id={id} labels={labels}/>
        </div>
    );
}

export default IssuesOverview;
