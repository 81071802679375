import { useTranslation } from 'react-i18next';
import { Col, Row, Button, notification, Select } from 'antd';
import NavMenu from "../../components/Assets/NavMenu";
import WorkstationDetails from "../../components/Assets/WorkstationDetails";
import AddWorkstationModal from "../../components/Assets/AddWorkstationModal";
import AssetDetails from "../../components/Assets/AssetDetails";
import { fetchApi } from "../../utils/RequestBuilder";
import {useState, useEffect} from "react";
import { PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { useAuth } from 'react-oidc-context';
import Scrollbars from "react-custom-scrollbars-2";
import ChangeWorkstationPositionModal from "../../components/Assets/ChangeWorkstationPositionModal";

function AssetsManufacturer() {

    //Auth
    const auth = useAuth();

    const [assetList, setAssetList] = useState([])
    const { t } = useTranslation();

    const [customers, setCustomers] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [asset, setAsset] = useState({selectId: "", name: "", internal_identifier: "", id: "", position: "", type: "", manufacturer: "", equipment_number: ""});
    const [workstation, setWorkstation] = useState({selectId: "", name: "", internal_identifier: "", id: "", position: ""});
    const [workstationSelected, setWorkstationSelected] = useState(true);
    const [menuItemKey, setMenuItemKey] = useState(["001"]);

    const [addWsModalIsOpen, setWsModalIsOpen] = useState(0);
    const [changeWsPositionModalIsOpen, setChangeWsPositionModalIsOpen] = useState(0);

    const [api, contextHolder] = notification.useNotification();
    const [forceRerender, setForceRerender] = useState(0);
    const [hashValue, setHashValue] = useState("");

    //Fetch customers from server
    async function loadCustomers() {
        try {
            const jsonData = await fetchApi(auth, "/customers")
            setCustomers(jsonData)
        } catch (error) {
            // Handle error
        }
    }

    function buildCustomerOptions() {
        let temp = [];
        if (customers && customers.length > 0) {
            temp = customers.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        setCustomerOptions(temp);
        }
    }

    useEffect(() => {
        buildCustomerOptions();
        // eslint-disable-next-line
     }, [customers]);

    useEffect(() => {
       loadAssets();
        // eslint-disable-next-line
    }, [forceRerender]);

    useEffect(() => {
        loadCustomers();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadAssets();
        // eslint-disable-next-line
    }, [selectedCustomer]);

    
    function onCustomerChange(option) {
        window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#asset-management"
        setHashValue("")
        setMenuItemKey(["001"])
        setSelectedCustomer(option)
    }

    // TODO - was macht diese Funktion?
    useEffect(() => {
        if (hashValue !== "") {
            let matchingEntry = null
            assetList.forEach(wsEntry => {
                if (wsEntry.id === hashValue) {
                    matchingEntry = wsEntry
                } else {
                    const assetEntry = wsEntry.assets.find(assetEntry => assetEntry.id === hashValue)
                    if (assetEntry) {
                        matchingEntry = assetEntry
                    }
                }
            })
        }
        // eslint-disable-next-line
     }, [hashValue]);

    //Fetch assets from Server
    async function loadAssets() {
        if (selectedCustomer !== null) {
            try {
                const jsonData = await fetchApi(auth, "/customers/" + selectedCustomer + "/assets")
                setAssetList(jsonData);
            } catch (error) {
                // Handle error
            }
        }
    }

    useEffect(() => {
        if (window.location.hash && assetList.length !== 0) {
            if (window.location.hash !== "#asset-management") {
                setHashValue(window.location.hash.replace("#", ""));
            }
        }
    }, [assetList]);

    const setSelection = (selectId, name, selectType, internal_identifier, id, position, type, type_id, manufacturer, equipment_number) => {
        if (selectType === "workstation") {
            setWorkstation({
                selectId: selectId,
                name: name,
                internal_identifier: internal_identifier,
                id: id,
                position: position
            })
            setAsset({
                selectId: "",
                name: "",
                internal_identifier: "",
                id: "",
                position: "",
                type: "",
                type_id: "",
                manufacturer: "",
                equipment_number: ""
            })
            setWorkstationSelected(true);
            window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+ id
        } else {
            setAsset({
                selectId: selectId,
                name: name,
                internal_identifier: internal_identifier,
                id: id,
                position: position,
                type: type,
                type_id: type_id,
                manufacturer: manufacturer,
                equipment_number: equipment_number
            })
            setWorkstationSelected(false);
            window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+ id
        }
    }

    const openWsModal = () => {
        setWsModalIsOpen(addWsModalIsOpen + 1)
    };
    const openWsPositionModal = () => {
        setChangeWsPositionModalIsOpen(changeWsPositionModalIsOpen + 1)
    };

    return (
        <>
            {contextHolder}
            <div>
                <Row>
                    <Col span={4} >
                        <h1>{t('assets.overview.customer-select')}:</h1>
                        <h1>{t('assets.overview.header')}:</h1>
                    </Col>
                    <Col span={20} style={{display: "flex", flexDirection: "column", marginTop: 19}}>
                        <div style={{ marginBottom: 19 }}>
                            <Select
                                options={customerOptions}
                                style={{minWidth: 200}}
                                placeholder={t('assets.overview.customer-select-placeholder')}
                                onChange={onCustomerChange}
                            >
                            </Select>
                        </div>
                        <div style={{ display: "flex", justifyContent: "start" }}>
                            <Button
                                onClick={() => openWsModal()}
                                type="primary"
                                style={{ marginRight: "10px" }}
                                disabled={selectedCustomer === null}
                            >
                                    <PlusOutlined />
                                    {t('assets.overview.add-workstation')}
                            </Button>
                            <Button
                                onClick={() => openWsPositionModal()}
                                type="primary"
                                disabled={selectedCustomer === null}
                            >
                                    <SwapOutlined rotate={90} />
                                    {t('assets.overview.change-ws-position')}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <div>
                            <Scrollbars
                                autohide="true"
                                style={{minHeight: "80vh"}}
                            >
                                <NavMenu 
                                    setSelection={setSelection} 
                                    assetList={assetList}
                                    menuItemKey={menuItemKey}
                                    hashValue={hashValue}
                                />
                            </Scrollbars>
                        </div>
                    </Col>
                    <Col span={20}>
                        {
                            workstationSelected ?
                                <WorkstationDetails
                                    selectId={workstation.selectId} 
                                    name={workstation.name} 
                                    setSelection={setSelection} 
                                    auth={auth}
                                    id={workstation.id}
                                    assets={assetList} 
                                    callbackFct={loadAssets}
                                    setMenuItemKey={setMenuItemKey}
                                    menuItemKey={menuItemKey}
                                    setForceRerender={setForceRerender}
                                    customer={selectedCustomer}
                                />
                                : 
                                <AssetDetails 
                                    selectId={asset.selectId} 
                                    name={asset.name} 
                                    auth={auth}
                                    id={asset.id} 
                                    type={asset.type} 
                                    type_id={asset.type_id}
                                    manufacturer={asset.manufacturer}
                                    equipment_number={asset.equipment_number}
                                    callbackFct={loadAssets}
                                    setMenuItemKey={setMenuItemKey} 
                                    assets={assetList}
                                    setForceRerender={setForceRerender}
                                    customer={selectedCustomer}
                                />
                        }
                    </Col>
                </Row>
                <AddWorkstationModal
                    isOpen={addWsModalIsOpen}
                    auth={auth}
                    callbackFct={loadAssets}
                    customerId={selectedCustomer}
                />
                <ChangeWorkstationPositionModal 
                    isOpen={changeWsPositionModalIsOpen} 
                    auth={auth} 
                    setSelection={setSelection} 
                    externalSetKey="" 
                    assetList={assetList} 
                    menuItemKey={menuItemKey} 
                    setForceRerender={setForceRerender}
                    customer={selectedCustomer}
                />
            </div>
        </>
        
    );
}

export default AssetsManufacturer;
