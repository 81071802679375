import { Card } from 'antd';
import Scrollbars from 'react-custom-scrollbars-2';

function CardTemplate({name, content}) {
    return (
        <Scrollbars
            autohide="true"
            style={{minHeight: "54vh"}}
        >
            <Card
                className="tickets-details-card"
                title={name}
                style={{minHeight: "10vh"}}
            >
                {content}
            </Card>
        </Scrollbars>
    )
}

export default CardTemplate;
