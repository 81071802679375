import { useRouteError } from "react-router-dom";
import {useTranslation} from "react-i18next";

function ErrorPage() {
    const { t } = useTranslation();

    const error = useRouteError();
    console.error(error);

    return (
        <div>
            <h1>{t('error')}</h1>
            <p>
                <i>Error: {error.statusText || error.message}</i>
            </p>
        </div>
    );
}

export default ErrorPage;
