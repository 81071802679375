import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function Footer() {
    const { t } = useTranslation();

    return (
        <footer
            className="ant-layout-footer, footer"
        >
            {/*<Link to={`/terms-and-conditions`}>{t('footer.terms')}</Link>*/}
            <a href="https://www.primetals.com/press-media/imprint-privacy-policy/">{t('footer.imprint')}</a>
            <span className="footer-copyright">&copy; {new Date().getFullYear()} Primetals Technologies</span>
        </footer>
    )
}

export default Footer