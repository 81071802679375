import {Select} from "antd";
import {useTranslation} from "react-i18next";

function Sort({setSortOrder}) {

    const { t } = useTranslation();

    return (
        <Select
            defaultValue= {t('tickets.sorter.updated-at-desc')}
            onChange= {(value) => setSortOrder(value)}
            style={{
                width: 160,
                marginRight: 15,
            }}
            options={[
                {
                    value: 1, //updated_at_desc
                    label: t('tickets.sorter.updated-at-desc'),
                },
                {
                    value: 2, //updated_at_asc
                    label: t('tickets.sorter.updated-at-asc'),
                },
                {
                    value: 3, //alphabetically_asc
                    label: t('tickets.sorter.alphabetically-desc'),
                },
                {
                    value: 4, //alphabetically_desc
                    label: t('tickets.sorter.alphabetically-asc'),
                }
            ]}
        />
    )
}

export default Sort