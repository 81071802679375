import { Form, Input, Select, Upload, Avatar, Space, Badge, Row, Col, Tag, Cascader } from 'antd';
import { CustomerServiceOutlined, CarOutlined, PhoneOutlined, LaptopOutlined, UserOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { getAvatarColor } from '../../../utils/utils';
import { getPrioTagColor } from '../../../utils/utils';
import { fetchApi } from '../../../utils/RequestBuilder';

const { TextArea } = Input;
const { Option } = Select;


function TicketCreateForm({auth, form, assets, userList, loggedInUser}) {
    const [assetOptions, setAssetOptions] = useState([])
    const [users, setUsers] = useState(userList)
    const { t } = useTranslation();
    //For Service
    const [possibleServices, setPossibleServices] = useState({});
    const [serviceOptions, setServiceOptions] = useState([]);
    const [caseOptions, setCaseOptions] = useState([]);
    const [caseOptionsForService, setCaseOptionsForService] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState();

    const initialStatus = 'OPEN';

    //Fetch Modules and Issues from Server
    async function loadCasesData(id) {
        try {
            const jsonData = await fetchApi(auth, "/assets/" + id + "/service-options")
            setCaseOptions(jsonData);
        } catch (error) {
            // Handle error
            console.log(error)
        }
    }

    //Fetch Services from server
    async function loadServicesForAsset(asset) {
        if (asset !== undefined) {
            try {
                const jsonData = await fetchApi(auth, "/assets/" + asset + "/service-availabilities")
                setPossibleServices(jsonData)
            } catch (error) {
                // Handle error
            }
        }
    }

    //Fetch Modules from server
    async function loadModulesForAsset(asset) {
        if (asset !== undefined) {
            try {
                const jsonData = await fetchApi(auth, "/assets/" + asset + "/service-options")
                setCaseOptions(jsonData)
            } catch (error) {
                // Handle error
            }
        }
    }

    const getServiceIcon = (type) => {
        let icon;
        switch (type) {
            case "ONSITE":
                icon = <CarOutlined />;
                break;
            case "REMOTE":
                icon = <LaptopOutlined />;
                break;
            case "GUIDED":
                icon = <CustomerServiceOutlined/>;
                break;
            case "HOTLINE":
                icon = <PhoneOutlined />;
                break;
            case "PERSONAL":
                icon = <UserOutlined />;
                break;
            default:
                icon = ""
        } return icon
    }

    const getServiceText = (type) => {
        let text = "";
        switch (type) {
            case "ONSITE":
                text= t('administration.service-types.onsite')
                break;
            case "REMOTE":
                text= t('administration.service-types.remote')
                break;
            case "GUIDED":
                text= t('administration.service-types.guided')
                break;
            case "HOTLINE":
                text= t('administration.service-types.hotline')
                break;
            case "PERSONAL":
                text= t('administration.service-types.personal')
                break;
            default:
                text = ""
        } return text
    }

    const buildServiceOptions = (possibleServices) => {
        let options = []

        for (const [key, value] of Object.entries(possibleServices)) {
            options.push({
                value: key,
                label: (
                    <div style={{ display: "flex", alignItems: "center"}}>
                        {getServiceIcon(key)}{""} 
                        <span style={{marginLeft: "4px"}}>{getServiceText(key)}</span> 
                    </div>
                ),
                disabled: !value
            });    
        }
        return options;
    }


    const onCascaderChange = (value) => {
    };

    const handleServiceChange = (value) => {
        setCaseOptionsForService(getModulesByService(value));
    }

    const getModulesByService = (label) => {
        console.log(caseOptions)
        if (caseOptions[label]) {
            
            const element = caseOptions[label];
            const updatedElement = element.map(item => {
                const updatedIssues = item.issues.map(issue => ({
                    ...issue,
                    value: issue.id
                }));
                return {
                    ...item,
                    children: updatedIssues,
                    value: item.id
                };
            });
            updatedElement.sort((a, b) => a.label.localeCompare(b.label));
            return updatedElement;
        } else {
            return null; 
        }
    }

    

    const onValuesChange = (changedValues, allValues) => {
        if ('serviceSelect' in changedValues) {
            form.setFieldsValue({
                caseSelect: undefined,
            });
        }
        if ('asset' in changedValues) {
            form.setFieldsValue({
                serviceSelect: undefined,
                caseSelect: undefined,
            });
        }

    };

    useEffect(() => {
        setServiceOptions(buildServiceOptions(possibleServices));
        
    }, [possibleServices]);

    useEffect(() => {
        if (selectedAsset !== null && selectedAsset !== undefined) {
            loadServicesForAsset(selectedAsset);
            loadModulesForAsset(selectedAsset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAsset])
    

    const onFinish = (values) => {
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }


    const buildAssetOptions = () => {
        let options = []

        assets.forEach((area) => {
            let tmpAreaAssets = []

            area.assets.forEach((asset) => {
                tmpAreaAssets.push({
                    label: asset.name,
                    value: asset.id,
                    position: asset.position
                })
            })

            tmpAreaAssets.sort((a, b) => a.position - b.position);

            if (tmpAreaAssets.length > 0) {
                let tmpAssetOptions = {
                    label: area.name,
                    options: tmpAreaAssets
                }
            options.push(tmpAssetOptions)
            }
        })

        options.sort((a, b) => {
            const positionA = a.options.length > 0 ? a.options[0].position : 0;
            const positionB = b.options.length > 0 ? b.options[0].position : 0;
            return positionA - positionB;
        });

        setAssetOptions(options)
    }

    useEffect(() => {
        buildAssetOptions()
    }, [assets]);

    useEffect(() => {
    }, users)

    return (
        <Form
            {...layout}
            autoComplete="off"
            name="basic"
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
                status: initialStatus,
                assigned_to: loggedInUser
            }}
        >
            <Form.Item
                label={t('tickets.forms.create.name')}
                name="title"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.name') + ' ' + t('tickets.forms.create.error-message')
                }]}  
            >
                <Input showCount maxLength={48} placeholder={t('tickets.details.services-area.forms.create.placeholder.name')}/>
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.description')}
                name="description"
                rules={[{ 
                    required: true,
                    message: t('tickets.forms.create.description') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <TextArea rows={4} placeholder={t('tickets.details.services-area.forms.create.placeholder.description')} />
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.asset')}
                name="asset"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.asset') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <Select
                    options={assetOptions}
                    onChange={(label, option) => {setSelectedAsset(option.value); console.log(option.value)}}
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.asset')}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.details.services-area.forms.create.labels.type')}
                name="serviceSelect"
                rules={[{
                    required: true,
                    message: t('tickets.details.services-area.forms.create.labels.type') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                }]}
            >
                <Select
                    options={serviceOptions}
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.type')}
                    onChange={handleServiceChange}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.details.services-area.forms.create.labels.case')}
                name="caseSelect"
            >
                <Cascader 
                    options={caseOptionsForService} 
                    onChange={onCascaderChange} 
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.case')}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.priority')}
                name="priority"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.priority') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <Select
                    style={{ 
                    }}
                    placeholder={t('tickets.details.services-area.forms.create.placeholder.priority')}
                    options={[
                        {
                            value: 'MINOR',
                            label: <Tag color={getPrioTagColor('MINOR')}>MINOR</Tag>
                        },
                        {
                            value: 'MAJOR',
                            label: <Tag color={getPrioTagColor('MAJOR')}>MAJOR</Tag>
                        },
                        {
                            value: 'CRITICAL',
                            label: <Tag color={getPrioTagColor('CRITICAL')}>CRITICAL</Tag>
                        }
                    ]}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.assigned_to')}
                name="assigned_to"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.assigned_to') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <Select 
                    //options={assetOptions}
                    showSearch
                    filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                    optionFilterProp="children"
                    
                >
                    { 
                        (users || []).map((user) => {
                            return(
                                <Option key={user.id} value={user.id} searchterm={user.name}>
                                    <Avatar style={{ backgroundColor: getAvatarColor(user.signature)}}>
                                        {user.signature}
                                    </Avatar>  {user.name}
                                </Option>);
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.status')}
                name="status"
                rules={[{ required: true}]}
            >
                <Select
                    style={{
                    
                    }}
                    disabled
                    //defaultValue="OPEN"
                    options={[
                        {
                            value: 'OPEN',
                            label: <Badge status="warning" text="Open" />
                        },
                        {
                            value: 'IN_PROGRESS',
                            label: <Badge color="#00B5E2" text="In Progress" />
                        },
                        {
                            value: 'CLOSED',
                            label: <Badge status="success" text="Closed" />
                        }
                    ]}
                >
                </Select>
            </Form.Item>
            {/* <Form.Item
                label={t('tickets.forms.create.attachments')}
            >
                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                    <Upload.Dragger name="files" action="/upload.do">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item> */}
        </Form>
    )
}

export default TicketCreateForm;
