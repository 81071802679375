import "react-chat-elements/dist/main.css"
import React, { useState, useEffect, useContext, useRef } from 'react';
import { MessageList } from 'react-chat-elements'
import { Input, Button, Divider, Row, Col, notification, List } from 'antd'
import { SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../utils/GlobalContext";
import { fetchApi, sendNewMessage, changeMsgInfo } from "../../../utils/RequestBuilder";

function ChatArea({id, auth, hasManufacturerRole}) {
    const { t } = useTranslation();
    const [ msgInput, setMsgInput ] = useState('');
    const [ msgRawData, setMsgRawData ] = useState([]);
    const [ msgDisplayData, setMsgDisplayData ] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const notificationContext  = useContext(GlobalContext);
    const messageListRef = useRef(null);
    const {TextArea} = Input;
    //const messageListReference = React.createRef();

    const scrollToBottom = () => {
        const messageListElement = messageListRef.current;
        if (messageListElement) {
            if (navigator.userAgent.toLowerCase().includes('firefox')) {
                messageListElement.scrollIntoView({
                    block: "end",
                    behavior: "smooth"
                });
            } else {
                messageListElement.scrollIntoView({
                    block: "end",
                })
            }
        }
    };

    

    useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "message_created":
                    let msgProcessArray = [...msgDisplayData];
                    let positionValue;
                    let statusValue;
                    if (notificationContext.payload.type === "text" && notificationContext.payload.participant === "customer"){
                        switch (hasManufacturerRole) {
                            case true:
                                positionValue = "left";
                                statusValue = "";
                                break;
                            case false:
                                positionValue = "right";
                                statusValue = notificationContext.payload.status;
                                break;
                            default:
                                positionValue = "left";
                                statusValue = "";
                                break;
                        }
                    } else if (notificationContext.payload.type === "text" && notificationContext.payload.participant === "manufacturer"){
                        switch (hasManufacturerRole) {
                            case true:
                                positionValue = "right";
                                statusValue = notificationContext.payload.status;
                                break;
                            case false:
                                positionValue = "left";
                                statusValue = "";
                                break;
                            default:
                                positionValue = "right";
                                statusValue = notificationContext.payload.status;
                                break;
                        }
                    }
                    const newMessage = {
                        type: notificationContext.payload.type,
                        participant: notificationContext.payload.participant,
                        position: positionValue,
                        title: notificationContext.payload.title,
                        text: notificationContext.payload.text,
                        status: statusValue,
                        date: notificationContext.payload.date,
                        id: notificationContext.payload.id
                    };
                    msgProcessArray.push(newMessage);
                    setMsgDisplayData(msgProcessArray);
                    if ((hasManufacturerRole && notificationContext.payload.participant === "customer") || (!hasManufacturerRole && notificationContext.payload.participant === "manufacturer")){
                        changeMsgInfo(auth, "/tickets/" + id + "/messages/" + notificationContext.payload.id, "read");
                    }
                    break;
                case "message_updated":
                    if (notificationContext.payload && notificationContext.payload.id) {
                        let msgUpdateArray = [...msgDisplayData];
                        let msgIndex = msgUpdateArray.findIndex(element => element.id == notificationContext.payload.id); 
                        if (msgIndex !== -1) {
                            msgUpdateArray[msgIndex].date = notificationContext.payload.date;
                            if ((hasManufacturerRole && notificationContext.payload.participant === "manufacturer") || (!hasManufacturerRole && notificationContext.payload.participant === "customer")) {
                                msgUpdateArray[msgIndex].status = notificationContext.payload.status;
                            }
                            setMsgDisplayData(msgUpdateArray);
                        }
                    }
                    break;
                default:
                    break; 
            }
        }
    }, [notificationContext])
    
    //Fetch messages from Server
    async function loadMessageData() {
        try {
            const jsonData = await fetchApi(auth, "/tickets/" + id + "/messages")
            setMsgRawData(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    useEffect(() => {
        setTimeout(scrollToBottom, 500);
    }, [msgDisplayData])

    //Initial component call
    useEffect(() => {
        if (auth.user !== undefined) {
            loadMessageData();
        }
        // eslint-disable-next-line
    }, [auth])

    //Prepare messages for displaying
    useEffect(() => {
        let msgProcessArray = [];
        msgProcessArray = [...msgRawData];
        msgProcessArray.forEach(function(item){
            if (item.type === "text" && item.participant === "customer"){
                switch (hasManufacturerRole) {
                    case true:
                        item.position = "left";
                        item.status = "";
                        break;
                    case false:
                        item.position = "right";
                        break;
                    default:
                        item.position = "left";
                        item.status = "";
                        break;
                }
            } else if (item.type === "text" && item.participant === "manufacturer"){
                switch (hasManufacturerRole) {
                    case true:
                        item.position = "right";
                        break;
                    case false:
                        item.position = "left";
                        item.status = "";
                        break;
                    default:
                        item.position = "right";
                        break;
                }
            }
        })
        setMsgDisplayData(msgProcessArray)
    }, [msgRawData])

    useEffect(() => {
        if (msgInput === "") {
            document.getElementById("msgInput").value = "";
        }
        // eslint-disable-next-line
    }, [msgInput])


    function handleInputChange(event) {
        setMsgInput(event.target.value);  
        //Test für das Ändern des Status einer message
        /* changeStatusUI(1, "read");
        changeStatusUI(2, "read");
        changeStatusUI(3, "read");
        changeStatusUI(4, "read");
        changeStatusUI(5, "read"); 
        changeStatusBE(5, "waiting"); */
    }

    //change message status in UI
    function changeStatusUI(msgId, newStatus) {
        let msgProcessArray = [...msgDisplayData];
        const elementToUpdate = msgProcessArray.find(item => item.id === msgId)
        if (elementToUpdate) {
            elementToUpdate.status = newStatus;
        }
        setMsgDisplayData(msgProcessArray)
    }

    //change message Status in BE
    async function changeStatusBE(msgId, newStatus) {
        try {
            const result = changeMsgInfo(auth, "/tickets/" + id + "/messages/" + msgId, newStatus);

        } catch (error) {
            console.error(error);
        }
    }

    //format text to show line breaks
    function formatMessageText(text) {
        return text.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    }

    async function sendMessage() {
        if (msgInput !== "") {
            //Add message to displayed message list
        /*     let msgProcessArray = [];
            const newMessage = {
                type: "text",
                participant: "customer",
                position: "right",
                title: loggedInUser,
                text: msgInput,
                status: "waiting",
                date: new Date().toISOString()
            };
            msgProcessArray = msgDisplayData;
            msgProcessArray.push(newMessage);
            setMsgDisplayData(msgProcessArray); */
            //Send Message and await status
            const attributesArray = [
                {attributeName: "type", attributeValue: "text"},
                {attributeName: "text", attributeValue: msgInput}
            ]
            try {
                const result = await sendNewMessage(auth, "/tickets/" + id + "/messages", attributesArray);
                handleResult(result);
            } catch (error) {
                console.error(error);
            }
        }
    }

    //Show alert after error while sending message
    const openErrorNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };
    function showAlert(response) {
        let desc = t('tickets.details.chat-area.update-message.body.error');
        let title = t('tickets.details.chat-area.update-message.title.error');
        let errorCode = '[' + t('tickets.details.chat-area.update-message.status-code') + ': ' + response + ']' 
        openErrorNotification('topRight', 'error', desc, title, errorCode)  
    }

    function handleResult(status){
        if (status !== 200) {
            showAlert(status);
            //TODO Change status of message from 'waiting# to 'sent'
            /* let msgProcessArray = [];
            msgProcessArray = msgDisplayData;
            msgProcessArray[msgProcessArray.length - 1].status = "sent";
            setMsgDisplayData(msgProcessArray); */
        }
    }

    function handleMsg () {
        //setMsgRawData(testDataSource);
        sendMessage();
        //Reset input field
        //document.getElementById("msgInput").value = "";
        /* if (inputRef.current) {
            inputRef.current.value = "";
        } */
        setMsgInput("");
        const inputElement = document.getElementById("msgInput");
        if (inputElement) {
            inputElement.blur(); 
            inputElement.value = '';
            inputElement.focus(); 
        }
    }
    
    return (
        <>
            {contextHolder}
            <div>
                {msgDisplayData.length > 0 ? (
                    <MessageList
                        referance={messageListRef}
                        className='message-list'
                        lockable={false}
                        downButton={true}
                        toBottomHeight={'100%'}
                        dataSource={msgDisplayData.map(message => ({
                            ...message,
                            text: formatMessageText(message.text)
                        }))}
                        style= {{backgroundColor: "#f5f5f5" }}
                    />
                ) : (
                    <> 
                        <List bordered style={{backgroundColor: '#FAFAFA', marginTop: 16}}>
                            <List.Item>
                                <div style={{width: "100%", textAlign: "center"}}>
                                    <ExclamationCircleOutlined /><span style={{marginLeft: 4}}>{t('tickets.details.chat-area.no-chat')}</span>
                                </div>
                            </List.Item>
                        </List>
                    </>
                )}
                <Divider />
                    <Row gutter={16}>
                        <Col span={20}>
                            <TextArea 
                                //minRows={2}
                                //maxRows={6}
                                autoSize={true}
                                id="msgInput"
                                //ref={inputRef}
                                bordered={true}
                                placeholder={t('tickets.details.chat-area.placeholder')}
                                onChange={handleInputChange}
                                value={msgInput}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.shiftKey) {
                                        e.preventDefault();
                                        setMsgInput((prev) => prev + '\n')
                                    }
                                    else if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleMsg();
                                    }
                                }}
                            />
                        </Col>
                        <Col span={4}>
                            <Button 
                                type="primary"
                                onClick={handleMsg}
                            >   
                                <SendOutlined /> {t('tickets.details.chat-area.send')}
                            </Button>  
                        </Col>
                    </Row> 
            </div>
        </>
    );
}

export default ChatArea;
