import {Col, Descriptions, Row, Table, Form, InputNumber,Input, Popconfirm, Typography, Avatar, Button, notification} from "antd";
import {useTranslation} from "react-i18next";
import React, { useState, useEffect } from 'react';
import { MinusSquareFilled, EditOutlined, PlusOutlined, DeleteOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import { changeServiceInfo, createNewTicket, deleteTimeBookingEntry } from "../../../utils/RequestBuilder";
import { getAvatarColor } from "../../../utils/utils";
import { fetchApi } from "../../../utils/RequestBuilder";
import {
  red,
  volcano,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey
} from '@ant-design/colors';

function getDateFormat(lng)  {
  switch(lng) {
    case 'en':
      return 'YYYY/MM/DD hh:mm:ss a';
    case 'de':
      return 'DD.MM.YYYY HH:mm:ss';
    default:
      return 'YYYY/MM/DD hh:mm:ss a';
  }
}


function addKey(input) {
  let i = 1;
  input.forEach(function(element){
    element.key = i++;
  })
}


function TimeBookingArea({ticketId, auth, hasManufacturerRole}) {
    const [ ticketData, setTicketData ] = useState()
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    //Show Service Update Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response == 200) {
            title = t('tickets.details.time-booking-area.table.forms.create.title.success');
            desc = t('tickets.details.time-booking-area.table.forms.create.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('tickets.details.time-booking-area.table.forms.create.title.error');
            desc = t('tickets.details.time-booking-area.table.forms.create.body.error');
            errorCode = '[' + t('tickets.details.time-booking-area.table.forms.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }
    function showUpdateAlert(response) {
      let desc = "";
      let title = "";
      let errorCode = "";
      if (response == 200) {
          title = t('tickets.details.time-booking-area.table.forms.update.title.success');
          desc = t('tickets.details.time-booking-area.table.forms.update.body.success');
          openNotification('topRight', 'success', desc, title, errorCode)
      } else {
          title = t('tickets.details.time-booking-area.table.forms.update.title.error');
          desc = t('tickets.details.time-booking-area.table.forms.update.body.error');
          errorCode = '[' + t('tickets.details.time-booking-area.table.forms.status-code') + ': ' + response + ']'
          openNotification('topRight', 'error', desc, title, errorCode)
      }
    }
    function showDeleteAlert(response) {
      let desc = "";
      let title = "";
      let errorCode = "";
      if (response == 200) {
          title = t('tickets.details.time-booking-area.table.forms.delete.title.success');
          desc = t('tickets.details.time-booking-area.table.forms.delete.body.success');
          openNotification('topRight', 'success', desc, title, errorCode)
      } else {
          title = t('tickets.details.time-booking-area.table.forms.delete.title.error');
          desc = t('tickets.details.time-booking-area.table.forms.delete.body.error');
          errorCode = '[' + t('tickets.details.time-booking-area.forms.status-code') + ': ' + response + ']'
          openNotification('topRight', 'error', desc, title, errorCode)
      }
    }


    const EditableCell = ({
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    }) => {
      const editing = isEditing(record);
      const inputNode = inputType === 'number' ? <InputNumber min={0} precision={0}/> : <Input />;
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: t('tickets.details.time-booking-area.table.action.missing-input') + ` ${title}!`  ,
                },
                {
                  type: inputType
                },
                {
                  validator: (_, value) => {
                    if (inputType === 'number') {
                      if (typeof value === 'string' && (value.includes('.') || value.includes(','))) {
                        return Promise.reject(new Error(t('tickets.details.time-booking-area.table.action.invalid-number')));
                      }
                      if (value % 1 !== 0) {
                        return Promise.reject(new Error(t('tickets.details.time-booking-area.table.action.invalid-number')));
                      }
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    };

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [tempKey, setTempKey] = useState(-1);
    const isEditing = (record) => record && record.key === editingKey;

    function getNewTempKey() {
      const newTempKey = tempKey;
      setTempKey(newTempKey - 1);
      return newTempKey
    }


    async function loadTimeBookingData() {
      try {
          const jsonData = await fetchApi(auth, "/tickets/" + ticketId + "/time-bookings")
          addKey(jsonData);
          setData(jsonData);
      } catch (error) {
          // Handle error
      }
    }

    async function sendUpdatedData(id, booker, updatedData) {
      const timeBookingInformationArray = createTimeBookingArray(updatedData.description, updatedData.hours, updatedData.mins);
      handleTimeBookingUpdate(auth, timeBookingInformationArray, ticketId, id)
    }

    function createTimeBookingArray(description, hours, mins) {
      const timeBookingArray = [
          {attributeName: "description", attributeValue: description},
          {attributeName: "hours", attributeValue: hours},
          {attributeName: "mins", attributeValue: mins},
      ]
      return timeBookingArray;
    }

    async function handleTimeBookingUpdate(auth, attributesArray, ticketId, serviceId) {
      try {
          const result = await changeServiceInfo(auth, "/tickets/" + ticketId + "/time-bookings/" + serviceId , attributesArray);
          //showCreateAlert(result);
          showUpdateAlert(result);
          if (result === 200) {loadTimeBookingData();} 
      } catch (error) {
          console.error(error);
          //showAlert(error.status)
      }
    }

    async function handleTimeBookingAdd(auth, attributesArray, ticketId) {
      try {
          const result = await createNewTicket(auth, "/tickets/" + ticketId + "/time-bookings", attributesArray);
          //showCreateAlert(result);
          showCreateAlert(result);
          if (result === 200) {loadTimeBookingData();} 
      } catch (error) {
          //console.error(error);
          //showAlert(error.status)
      }
    }

    async function sendNewData(newData) {
      const timeBookingInformationArray = createTimeBookingArray(newData.description, newData.hours, newData.mins);
      handleTimeBookingAdd(auth, timeBookingInformationArray, ticketId);
    }

    async function sendDelete(entryId, ticketId) {
      try {
        const response = await deleteTimeBookingEntry(auth, "/tickets/" + ticketId + "/time-bookings/" + entryId);
        if (response.status === 200) {
          showDeleteAlert(response.status);
          loadTimeBookingData()
          return 200;
        } else {
          return response.status;
        }
      } catch (error) {
        console.error("Error deleting entry: ", error)
        return error.status
      }
      
    }


    useEffect(() => {
        if (auth.user !== undefined) {
        loadTimeBookingData();
      }
      // eslint-disable-next-line
  }, [auth, ticketId])

    //Edit Entry
    const edit = (record) => {
      form.setFieldsValue({
        description: '',
        hours: '',
        mins: '',
        ...record,
      });
      setEditingKey(record.key);
    };

    //Cancel editing
    const cancel = () => {
      if (editingKey < 0) {
        const newData = data.filter((item) => item.key !== editingKey);
        setData(newData);
      }
      setEditingKey('');
    };

    //Save Entry
    const save = async (key) => {
      try {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);
          setEditingKey('');
          if (item.key > -1) {
            sendUpdatedData(newData[index].id, newData[index].booker, newData[index]);
          } else {
            sendNewData(newData[index]);
          }
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        console.log(errInfo);
      }
    };

    //Delete Entry
    const handleDelete = async (key) => {
      const entryToDelete = data.find((item) => item.key === key);
      try {
        const result = await sendDelete(entryToDelete.id, ticketId);
        /* if (result === 200) {
          const newData = data.filter((item) => item.key !== key);
          setData(newData);
        } */
      } catch (error) {
          console.error("Error deleting entry:", error);
      }
    };
    
    //Add Entry
    const handleAdd = () => {
      const newKey = getNewTempKey();
      const newDate = dayjs();
      const newData = {
        key: newKey,
        booker: auth.user.profile.name, 
        booker_signature: auth.user.profile.name.charAt(0) + auth.user.profile.family_name.charAt(0),

        description: "",
        hours: 0,
        mins: 0,
        date: newDate
      };
      setData([newData, ...data,]);
      setEditingKey(newKey);
      form.resetFields();

      }
    
    

    

    const columns = 
    [
      {
          title: t('tickets.details.time-booking-area.table.header.date'),
          dataIndex: 'date',
          render: (date) => <div>{dayjs(date).format(getDateFormat(i18n.language))}</div>,
          key: 'date',
      },
      {
          title: t('tickets.details.time-booking-area.table.header.booker'),
          dataIndex: 'booker',
          key: 'booker',
          render: (booker, ticket) => <div><Avatar style={{ backgroundColor: getAvatarColor(ticket.booker_signature)}}>{ticket.booker_signature}</Avatar>  {booker}</div>,
      },
      {
          title: t('tickets.details.time-booking-area.table.header.description'),
          dataIndex: 'description',
          key: 'description',
          editable: true,
      },
      {
          title: t('tickets.details.time-booking-area.table.header.hours'),
          dataIndex: 'hours',
          key: 'hours',
          editable: true,
      },
      {
          title: t('tickets.details.time-booking-area.table.header.minutes'),
          dataIndex: 'mins',
          key: 'mins',
          editable: true,
      },
      hasManufacturerRole && 
      {
        title: t('tickets.details.time-booking-area.table.header.action'),
        dataIndex: 'action',
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                <SaveOutlined
                  style= {{fontSize: '150%', marginRight: '16px', color: '#389e0d'}} 
                />
              </Typography.Link>
              <Popconfirm
                title={t('tickets.details.time-booking-area.table.popconfirm.cancel.title')} 
                description={t('tickets.details.time-booking-area.table.popconfirm.cancel.description')}
                okText={t('tickets.details.time-booking-area.table.popconfirm.cancel.okText')}
                cancelText={t('tickets.details.time-booking-area.table.popconfirm.cancel.cancelText')}
                onConfirm={cancel}>
                <a><CloseOutlined style= {{fontSize: '150%', color: '#595959'}} /></a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} >
                <EditOutlined
                  style= {{fontSize: '150%', marginRight: '16px', color: '#595959'}} 
                />
              </Typography.Link>
              <Typography.Link disabled={editingKey !== ''} >
                <Popconfirm 
                  title={t('tickets.details.time-booking-area.table.popconfirm.delete.title')}
                  description={t('tickets.details.time-booking-area.table.popconfirm.delete.description')}
                  okText={t('tickets.details.time-booking-area.table.popconfirm.delete.okText')}
                  cancelText={t('tickets.details.time-booking-area.table.popconfirm.delete.cancelText')}
                  onConfirm={() => handleDelete(record.key)}
                >
                  <DeleteOutlined
                    style= {{fontSize: '150%', color: '#cf1322'}} 
                  />
                </Popconfirm>
              </Typography.Link>
            </span>
          );
        },
      },
    ].filter(Boolean);

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.dataIndex === 'hours' || col.dataIndex === 'mins' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

    return (
      <>
        {contextHolder}
        <div>
          {hasManufacturerRole && (
            <Button
              type="primary"
              style={{
                marginBottom: 16,
              }}
              onClick={() => handleAdd()}
              disabled={editingKey !== ''}
            >
              <PlusOutlined /> {t('tickets.details.time-booking-area.button.new-entry')}
            </Button>
          )}
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      </>
    );


}

export default TimeBookingArea;
