import React, { createContext, useState, useEffect } from 'react';


const GlobalContext = createContext();

const websocketUrl =  process.env.REACT_APP_WEBSOCKET_URL

const GlobalContextProvider = ({ children, auth }) => {
    const [ notificationContext, setNotificationContext ] = useState({});


    useEffect(() => {
        const socket = new WebSocket(websocketUrl);
        socket.onopen = function() {


            setTimeout(function(){
                socket.send("Bearer " + auth.user.access_token)
            }, 2000);
        }
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if ("event" in data) {
                data.sendPush = true
                setNotificationContext(data);
            }
            };
            return () => {
        };
        // eslint-disable-next-line
    }, []);

    return (
        <GlobalContext.Provider value={notificationContext}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalContextProvider };