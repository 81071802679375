import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { Button, Row, Select, Space, Input, notification } from 'antd';
import { useState, useEffect, useContext } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { fetchApi } from "../../utils/RequestBuilder";
import RequestKanbanLists from "../../components/Requests/RequestsKanbanList";
import PersonFilter from "../../components/Requests/KanbanTools/PersonFilter";
import CustomerFilter from '../../components/Requests/KanbanTools/CustomerFilter';
import Sort from "../../components/Tickets/KanbanTools/Sort";
import {useAuth} from "react-oidc-context";
import AddRequestModal from '../../components/Requests/AddRequestModal';
import { GlobalContext } from "../../utils/GlobalContext";
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";


const { Search } = Input;


function Requests() {

    const navigate = useNavigate()

    //auth
    const auth = useAuth();
    const [user, setUser] = useState({sub: ""})
    const [hasServiceManagerRole, setRole] = useState(false);

    //Initial state
    const [ tickets, setTickets ] = useState([]);
    //Unfiltered Ticket Lists
    const [ openTickets, setOpenTickets] = useState([]);
    const [ progressTickets, setProgressTickets] = useState([]);
    const [ closedTickets, setClosedTickets] = useState([]);
    //Filtered Ticket Lists
    const [ filteredOpenTickets, setFilteredOpenTickets] = useState([]);
    const [ filteredProgressTickets, setFilteredProgressTickets] = useState([]);
    const [ filteredClosedTickets, setFilteredClosedTickets] = useState([]);

    const [ addTicketModalIsOpen, setTicketModalIsOpen ] = useState(0);

    const [ users, setUsers ] = useState([]);
    const [customers, setCustomers] = useState([])

    const [ sortOrder, setSortOrder] = useState(1);
    const [ searchTerm, setSearchTerm ] = useState("");
    const [ userFilter, setUserFilter ] = useState("");
    const [ userFilterValue, setUserFilterValue ] = useState(undefined);
    const [ customerFilter, setCustomerFilter ] = useState("");
    const [ customerFilterValue, setCustomerFilterValue ]= useState(undefined);
    const [ equipmentFilter, setEquipmentFilter] = useState("");

    const  notificationContext  = useContext(GlobalContext);
    const [api, contextHolder] = notification.useNotification();

    //Localisation
    const { t } = useTranslation();

    //Show push notification
    const openPushNotification = (placement, status, desc, title, ticketId, msgOrigin) => {
        api[status]({
            duration: 6.5,
            message: title,
            description:
                <div>
                    <div>
                        <b>{msgOrigin}</b> : {desc}
                    </div>
                    <br/>
                    <div>
                        <Link to={`/tickets/${ticketId}#chat`}>
                            {t('tickets.details.push.link')}
                        </Link>
                    </div>
                </div>
        });
    };
    function showPushNotification(msgString, ticketId, msgSender) {
        let desc = msgString.length > 50 ? `${msgString.substring(0, 50)}...` : msgString
        let title = t('tickets.details.push.title');
        let msgOrigin = msgSender;
        openPushNotification('topRight', 'info', desc, title, ticketId, msgOrigin)  
    }

    //Handle Context change
   /*  useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "message_created":
                    if ((notificationContext.payload.participant === "manufacturer" && !hasServiceManagerRole) || (notificationContext.payload.participant === "customer" && hasServiceManagerRole)) {
                        if (notificationContext.sendPush) {
                            showPushNotification(
                                notificationContext.payload.text,
                                notificationContext.relation_id,
                                notificationContext.payload.title
                            );
                            notificationContext.senPush = false;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext]) */

    //Open Modal for ticket creation
    const openTicketModal = () => {
        setTicketModalIsOpen(addTicketModalIsOpen + 1)
    };

    //Fetch tickets from Server
    async function loadTickets() {
        try {
            const jsonData = await fetchApi(auth, "/customers/tickets")
            setTickets(jsonData);
        } catch (error) {
            // Handle error
        }
    }

     //Fetch users from Server
    async function loadUsers() {
        try {
            const jsonData = await fetchApi(auth, "/manufacturer-engineers")
            setUsers(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Fetch customers from server
    async function loadCustomers() {
        try {
            const jsonData = await fetchApi(auth, "/customers")
            setCustomers(jsonData)
        } catch (error) {
            // Handle error
        }
    }

    // Check if user filter is present as GET parameter
    async function getDefaultUser() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get('user');

        if (userId != null) {
            users.forEach((user) => {
                if (user.id === userId) {
                    setUserFilter(user.name)
                    setUserFilterValue(user.id)
                }
                
            })
        }
    }

    // Check if customer filter is present as GET parameter
    async function getDefaultCustomer() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const customerId = params.get('customer');

        if (customerId != null) {
            customers.forEach((customer) => {
                if (customer.id === customerId) {
                    setCustomerFilter(customer.name)
                    setCustomerFilterValue(customer.id)
                }
                
            })
        }
    }

    function sortAllTickets() {
        sortTickets(openTickets, sortOrder, 'OPEN');
        sortTickets(progressTickets, sortOrder, 'IN_PROGRESS');
        sortTickets(closedTickets, sortOrder, 'CLOSED');
    }

    function customFilterFunction(inputArray, column) {
        let filteredStatusArray = [...inputArray];
        //Apply customer filter
        if (customerFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) =>
                item.customer_name.toLowerCase() === customerFilter.toLowerCase()
            )
        }
        //Apply user filter
        if (userFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) => {
                return item.manufacturer_engineer_name.toLowerCase() === userFilter.toLowerCase()}
            )
        }
        //Apply text search filter
        if (searchTerm !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) =>
                item.description.toLowerCase().includes(searchTerm.toLowerCase()) || item.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }

        switch (column) {
            case 1:
                setFilteredOpenTickets(filteredStatusArray);
                break;
            case 2:
                setFilteredProgressTickets(filteredStatusArray);
                break;
            case 3:
                setFilteredClosedTickets(filteredStatusArray);
                break;
            default:
                break;
        }
    }

    function onSearchFieldChange(event) {
        if (event.target.value !== "") {
            setSearchTerm(event.target.value)
        }
    }

    function onCustomerFilterChange(value, option) {
        setCustomerFilter(option?.searchterm ?? '')
        setCustomerFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('customer', value);
        navigate(`?${searchParams.toString()}`);

    }

    function onUserFilterChange(value, option) {
        setUserFilter(option?.searchterm ?? '')
        setUserFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('user', value);
        navigate(`?${searchParams.toString()}`);
        
    }

    useEffect(() => {
    }, [userFilter])

    function sortTickets(ticketList, method, status) {
        let sortedTickets = [...ticketList]
        switch (method) {
            case 1: //Updated at desc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.updated_at);
                    const dateB = new Date(b.updated_at);

                    return dateB - dateA
                });
                break;
            case 2: //Updated at asc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.updated_at);
                    const dateB = new Date(b.updated_at);

                    return dateA - dateB
                });
                break;
            case 3: //Alphabetically desc
                (sortedTickets || []).sort((a,b) => {
                    return b.title.localeCompare(a.title)
                });
                break;
            case 4: //Alphabetically asc
                (sortedTickets || []).sort((a,b) => {
                    return a.title.localeCompare(b.title)
                });
                break;
            default:
                break;
        }
        switch (status) {
            case 'OPEN':
                setOpenTickets(sortedTickets);
                setFilteredOpenTickets(sortedTickets);
                break;
            case 'IN_PROGRESS':
                setProgressTickets(sortedTickets);
                setFilteredProgressTickets(sortedTickets);
                break;
            case 'CLOSED':
                setClosedTickets(sortedTickets);
                setFilteredClosedTickets(sortedTickets);
                break;
            default:
                break;
        }
    }

    //Create Array for specific Ticket status
    function getStatusArray(data, status) {
        let statusArray = [];
        if ( data !== undefined) {
            statusArray = (data || []).filter((element) => element.status === status)
        }
        return statusArray;
    }

    function onSortChange(order) {
        setSortOrder(order)
    }

    //Handle Change of any filter
    useEffect(() => {
        customFilterFunction(openTickets, 1)
        customFilterFunction(progressTickets, 2)
        customFilterFunction(closedTickets, 3)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, customerFilter, userFilter])

    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            setUser(auth.user.profile);
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER));
            loadTickets();
            loadCustomers();
            loadUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    //Set Lists for ticket statuses
    useEffect(() => {
        setOpenTickets(() => getStatusArray(tickets, 'OPEN'));
        setProgressTickets(() => getStatusArray(tickets, 'IN_PROGRESS'));
        setClosedTickets(() => getStatusArray(tickets, 'CLOSED'));

        setFilteredOpenTickets(() => getStatusArray(tickets, 'OPEN'));
        setFilteredProgressTickets(() => getStatusArray(tickets, 'IN_PROGRESS'));
        setFilteredClosedTickets(() => getStatusArray(tickets, 'CLOSED'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tickets])

    //Handle change of sorter method
    useEffect(() => {
        sortAllTickets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder])

    //Handle Change of user list
    useEffect(() => {
        getDefaultUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    //Handle Change of customer list
    useEffect(() => {
        getDefaultCustomer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers])



    return (
        <>
            {contextHolder}
            <div>
                <div id="sub-header"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >   <Space>
                    <h2>{t('requests.overview.header')}</h2>
                    <Button
                        onClick={() => openTicketModal()}
                        type="primary">
                        <PlusOutlined />
                        {t('tickets.overview.add')}
                    </Button>
                </Space>

                    <Space>
                        <CustomerFilter
                            value={customerFilterValue}
                            customers={customers}
                            onChange={onCustomerFilterChange}
                        />
                        <PersonFilter
                            value={userFilterValue}
                            onChange={onUserFilterChange}
                            users={users}
                        />
                        <Sort
                            setSortOrder={onSortChange}
                        />
                        <Search
                            style={{
                                width: 200,
                            }}
                            placeholder={t('requests.overview.filter.search')}
                            onChange={onSearchFieldChange}
                            enterButton
                        />
                    </Space>
                </div>
                <div id="sub-content">
                    <Row className="tickets-kanban" gutter={16}>
                        <RequestKanbanLists
                            tickets={filteredOpenTickets}
                            header={t('tickets.status.open')}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                        <RequestKanbanLists
                            tickets={filteredProgressTickets}
                            header={t('tickets.status.in-progress')}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                        <RequestKanbanLists
                            tickets={filteredClosedTickets}
                            header={t('tickets.status.closed')}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                    </Row>
                </div>
                <AddRequestModal
                    isOpen={addTicketModalIsOpen}
                    auth={auth}
                    customers={customers}
                    manufacturerEngineers={users}
                    callbackFct={loadTickets}
                />
            </div>
        </>
    );
}

export default Requests;
