import { Button, Table, Popconfirm, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getApi, deleteApi } from "../../../utils/RequestBuilder";
import { CustomerServiceOutlined, CarOutlined, PhoneOutlined, LaptopOutlined, UserOutlined} from "@ant-design/icons";


function NotificationsTable({auth, users, customers, assetTypesPlain, modules, editCallback, reload}) {

    const { t } = useTranslation();

    const [assetTypeList, setAssetTypeList] = useState([])
    const [criteriaList, setCriteriaList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [moduleList, setModuleList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [usersSelection, setUsersSelection] = useState([])
    const [selectedUser, setSelectedUser] = useState("");
    const [searchText, setSearchText] = useState("");

    async function loadCriteria() {
        try {
            const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/criteria")
            let customers = jsonData
            customers.forEach(customer => {
                customer.key = customer.id
            })
            setCriteriaList(customers);
        } catch (error) {
            console.log(error)
        }
    }

    function getServiceLabel(serviceType) {
        switch (serviceType) {
            case "GUIDED":
                return t('administration.service-types.guided');
                break;
            case "ONSITE":
                return t('administration.service-types.onsite');
                break;
            case "REMOTE":
                return t('administration.service-types.remote');
                break;
            case "PERSONAL":
                return t('administration.service-types.personal');
                break;
            default:
                return "-"
        }
    }

    const getServiceIcon = (type) => {
        let icon;
        switch (type) {
            case "ONSITE":
                icon = <CarOutlined />;
                break;
            case "REMOTE":
                icon = <LaptopOutlined />;
                break;
            case "GUIDED":
                icon = <CustomerServiceOutlined/>;
                break;
            case "HOTLINE":
                icon = <PhoneOutlined />;
                break;
            case "PERSONAL":
                icon = <UserOutlined />
                break;
            default:
                icon = ""
        } return icon
    }

    function getCustomerLabels(customers) {
        let customerString = ""

        if (Object.keys(customerList).length > 0) {
            customers.forEach((customerId) => {
                if (customerList[customerId] !== undefined) {
                    if (customerString === "") {
                        customerString = customerList[customerId].name
                    } else {
                        customerString += " | " + customerList[customerId].name
                    }
                }
            })
        }

        if (customerString === "") {
            customerString = "-"
        }

        return customerString
    }

    function getModuleLabels(modules) {
        let moduleString = ""

        if (moduleList.length > 0) {
            modules.forEach((moduleId) => {
                if (moduleString === "") {
                    moduleString = moduleList[moduleId].en
                } else {
                    moduleString += " | " + moduleList[moduleId].en
                }
            })
        }

        if (moduleString === "") {
            moduleString = "-"
        }

        return moduleString
    }

    function createMap(input, setStateFunc) {
        let map = []
        input.forEach((entry) => {
            map[entry.id] = entry
        })
        setStateFunc(map)
    }

    useEffect (() => {
        loadCriteria()
    }, [])

    useEffect(() => {
        createMap(modules, setModuleList)
    }, [modules]);

    useEffect(() => {
        createMap(assetTypesPlain, setAssetTypeList)
    }, [assetTypesPlain]);

    useEffect(() => {
        createMap(users, setUsersList)

        let selection = []
        users.forEach((user) => {
            selection.push({
                text: user.name,
                value: user.id
            })
        })
        setUsersSelection(selection)

    }, [users]);

    useEffect(() => {
        createMap(customers, setCustomerList)
    }, [customers]);

    useEffect(() => {
        if (selectedUser !== "") {
            loadCriteria()
        }
        // eslint-disable-next-line
    }, [selectedUser])

    useEffect(() => {
        if (reload > 0) {
            loadCriteria()
        }
        // eslint-disable-next-line
    },[reload])

    async function deleteCriteria(id) {
        try {
            const response = await deleteApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/criteria/" + id)
            showDeleteAlert(response)
        } catch (error) {
            console.log(error)
        }
        
        loadCriteria();
    }

    //Show Criteria Delete Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    function showDeleteAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response.status === 200) {
            title = t('administration.notifications.form.delete.criteria.delete-message.title.success');
            desc = t('administration.notifications.form.delete.criteria.delete-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('administration.notifications.form.delete.criteria.delete-message.title.error');
            desc = t('administration.notifications.form.delete.criteria.delete-message.body.error');
            errorCode = '[' + t('administration.notifications.form.delete.criteria.delete-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    const columns = [
        {
            title: t('administration.notifications.user'),
                /* (
                <Select
                    style={{minWidth: 200}}
                    placeholder={t('administration.mail-notifications.placeholder.users')}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => setSelectedUser(value)} 
                >
                    {users.map((user) => (
                        <Select.Option key={user.id} value={user.id}>
                            {user.name}
                        </Select.Option>
                    ))}
                </Select>
            ), */
            dataIndex: 'user',
            width: "15%",
            
            filters: usersSelection,
            onFilter: (value, record) => record.user_key === value,
            render: (_, record) => (
                <div>
                    {usersList[record.user_key] ? usersList[record.user_key].name : "-"}
                </div>
            )
        },
        {
            title: t('administration.notifications.customer'),
            dataIndex: 'customer',
            width: "20%",
            filters: Object.keys(customerList).map(customerId => ({ text: customerList[customerId].name, value: customerId })),
            onFilter: (value, record) => (record.customers.includes(value)) || (record.customers[0] === ""),
            render: (_, record) => (
                <div>
                    {record.customers.length > 0 ? getCustomerLabels(record.customers) : "-"}
                </div>
            )
        },
        {
            title: t('administration.notifications.asset-type'),
            dataIndex: 'assetType',
            width: "20%",
            filters: Object.keys(assetTypeList).map(assetTypeId => ({ text: assetTypeList[assetTypeId].name, value: assetTypeId })),
            onFilter: (value, record) => {
                
                return (record.asset_type == value) || (record.asset_type == 0);
            }, 
            render: (_, record) => (
                <div>
                    {assetTypeList[record.asset_type] ? assetTypeList[record.asset_type].name : "-"}
                </div>
                
            )
        },
        {
            title: t('administration.notifications.service'),
            dataIndex: 'service',
            width: "15%",
            render: (_, record) => (
                <div>
                    {getServiceIcon(record.service)}<span style={{marginLeft: "4px"}}>{getServiceLabel(record.service)}</span>
                </div>
            )
        },
        {
            title: t('administration.notifications.module'),
            dataIndex: 'module',
            width: "15%",
            key: 'module',
            render: (_, record) => (
                <div>
                    {getModuleLabels(record.modules)}
                </div>
            )
        },
        {
            title: t('administration.action-header'),
            dataIndex: 'action',
            width: "15%",
            key: 'action',
            render: (_, record) => (
                <div>
                    <Button
                        className="notification-btn-update"
                        primary
                        onClick={() => editCallback(record.id)}
                        style={{marginRight: "15px"}}
                    >
                        {t('administration.actions.update')}
                    </Button>
                    <Popconfirm
                     title={t('administration.actions.delete-confirm.title')}
                     description={t('administration.actions.delete-confirm.description')}
                     okText={t('tickets.details.time-booking-area.table.popconfirm.delete.okText')}
                     cancelText={t('tickets.details.time-booking-area.table.popconfirm.delete.cancelText')}
                     onConfirm={() => deleteCriteria(record.id)}>
                        <Button
                            className="notification-btn-delete"
                            danger
                        >
                            {t('administration.actions.delete')}
                        </Button>
                    </Popconfirm>
                </div>
            )
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        if (filters.user !== null) {
            setSelectedUser(filters.user)
        } else {
            setSelectedUser("")
        }
    };

    const filteredData = criteriaList.filter((record) => {
        const username = usersList[record.user_key] ? usersList[record.user_key].name : "";
        return username.toLowerCase().includes(searchText.toLowerCase());
    });


    return (
        <>
            {contextHolder}
            <Table
                columns={columns}
                dataSource={filteredData}
                onChange={onChange}
                pagination={{pageSize:8}}
            />
        </>
    )
}

export default NotificationsTable