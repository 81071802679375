import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";
import { Timeline, Tag } from "antd";
import { AlertOutlined, PhoneOutlined, CheckCircleOutlined, CarOutlined, CustomerServiceOutlined, LaptopOutlined, InfoCircleOutlined, UserSwitchOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from 'react';
import { fetchApi } from "../../../utils/RequestBuilder";
import { getPrioTagColor } from "../../../utils/utils";
import dayjs from "dayjs";



const bspTimeline =[
    {
        "entry_type": "service",
        "entry_data": {
            "type": "guided",
            "created_at": "2023-10-16 14:00:00",
        },
    },
    {
        "entry_type": "service",
        "entry_data": {
            "type": "hotline",
            "created_at": "2023-10-15 14:00:00",
        }
    },
    {
        "entry_type": "status_change",
        "entry_data": {
            "new_status": "IN_PROGRESS",
            "changed_at": "2023-10-14 14:00:00"
        }
    },
    {
        "entry_type": "status_change",
        "entry_data": {
            "new_status": "OPEN",
            "changed_at": "2023-10-14 14:00:00"
        }
    },
    {
    "entry_type": "status_change",
    "entry_data": {
        "new_status": "CLOSED",
        "changed_at": "2023-10-14 14:00:00"
    }
    },
    {
        "entry_type": "responsible_change_customer",
        "entry_data": {
            "user_id": "67ghzd-48djc-85hj-33b",
            "changed_at": "2023-10-13 14:00:00"
            /* "user_name": "Mick Ruppert" */
        }
    },
    {
        "entry_type": "responsible_change_customer",
        "entry_data": {
            "user_id": "67ghzd-48djc-85hj-33b",
            "changed_at": "2023-10-13 14:00:00"
            /* "user_name": "Mick Ruppert" */
        }
    },
    {
        "entry_type": "responsible_change_customer",
        "entry_data": {
            "user_id": "67ghzd-48djc-85hj-33b",
            "changed_at": "2023-10-13 14:00:00"
            /* "user_name": "Mick Ruppert" */
        }
    },
    {
        "entry_type": "responsible_change_customer",
        "entry_data": {
            "user_id": "67ghzd-48djc-85hj-33b",
            "changed_at": "2023-10-13 14:00:00"
            /* "user_name": "Mick Ruppert" */
        }
    },
    {
        "entry_type": "responsible_change_manufacturer",
        "entry_data": {
            "user_id": "6jjjjzd-4hdjc-85hj-poi",
            "changed_at": "2023-10-12 14:00:00"
            /* "user_name": "Jörg Lenze" */
        }
    },

]

const bspTimeline2= [
    {
        "event_type": "ticket_priority",
        "timestamp": "2024-08-25T20:21:11.56985Z",
        "value_old": "MAJOR",
        "value_new": "CRITICAL"
    },
    {
        "event_type": "ticket_title",
        "timestamp": "2024-08-25T20:26:29.615022Z",
        "value_old": "",
        "value_new": ""
    },
    {
        "event_type": "ticket_description",
        "timestamp": "2024-08-25T20:30:23.639897Z",
        "value_old": "",
        "value_new": ""
    },
    {
        "event_type": "ticket_troubleshooting",
        "timestamp": "2024-08-25T20:31:05.116358Z",
        "value_old": "",
        "value_new": ""
    },
    {
        "event_type": "ticket_status",
        "timestamp": "2024-08-25T20:32:09.941498Z",
        "value_old": "IN_PROGRESS",
        "value_new": "CLOSED"
    },
    {
        "event_type": "ticket_status",
        "timestamp": "2024-08-25T20:32:49.926867Z",
        "value_old": "CLOSED",
        "value_new": "IN_PROGRESS"
    },
    {
        "event_type": "ticket_assigned_to_manu",
        "timestamp": "2024-08-25T20:36:09.72176Z",
        "value_old": "ABP Service",
        "value_new": "Jörg Lenze"
    },
    {
        "event_type": "ticket_assigned_to_manu",
        "timestamp": "2024-08-25T20:37:22.039316Z",
        "value_old": " ",
        "value_new": "ABP Service"
    }
]

function TimelineArea ({ticket, auth}) {

    const { t, i18n } = useTranslation();
    const [serviceHistoryTimeline, setServiceHistoryTimeline] = useState([]);
    const [finalTimeline, setFinalTimeline] = useState ([]);
    const timelineContainerRef = useRef(null);


   


    //Fetch Service History from Server
    async function loadEventData() {
        try {
            const jsonData = await fetchApi(auth, "/tickets/" + ticket.id + "/events")
            setServiceHistoryTimeline(jsonData);
        } catch (error) {
            console.error(error);
        } 
    }

    function getDateFormatWithoutTime(lng)  {
        switch(lng) {
          case 'en':
            return 'YYYY/MM/DD';
          case 'de':
            return 'DD.MM.YYYY';
          default:
            return 'YYYY/MM/DD';
        }
      }

    const buildTimeline = () => {
        if (Array.isArray(serviceHistoryTimeline)) {

            const timelineCopy = [...serviceHistoryTimeline];
            const transformedTimeline = timelineCopy.map(item => {
                let dot = null;
                let label  = dayjs(item.timestamp).format(getDateFormatWithoutTime(i18n.language));
                let children = null;

                switch (item.event_type) {
                    case "ticket_priority": {
                        switch (item.value_new) {
                            case "MINOR":
                                dot = <InfoCircleOutlined style={{ fontSize: '16px'}}/>
                                children = <>{t('tickets.details.timeline-area.priority-change')}<Tag color={getPrioTagColor('MINOR')}>MINOR</Tag></>
                                break;
                            case "MAJOR":
                                dot = <InfoCircleOutlined style={{ fontSize: '16px'}}/>
                                children = <>{t('tickets.details.timeline-area.priority-change')}<Tag color={getPrioTagColor('MAJOR')}>MAJOR</Tag></>
                                break;
                            case "CRITICAL":
                                dot = <InfoCircleOutlined style={{ fontSize: '16px'}}/>
                                children = <>{t('tickets.details.timeline-area.priority-change')}<Tag color={getPrioTagColor('CRITICAL')}>CRITICAL</Tag></>
                                break;
                            default:
                                break;
                        }
                        break;
                    }    
                    case "ticket_status": {
                        switch (item.value_new) {
                            case "OPEN":
                                dot = <InfoCircleOutlined style={{ fontSize: '16px', color: '#FAAD14'}}/>
                                children = <>{t('tickets.details.timeline-area.status.message')}<br/>{t('tickets.details.timeline-area.status.open')}</>
                                break;
                            case "IN_PROGRESS":
                                dot = <InfoCircleOutlined style={{ fontSize: '16px', color: '#1677FF'}}/>
                                children = <>{t('tickets.details.timeline-area.status.message')} <br/> {t('tickets.details.timeline-area.status.in-progress')}</>
                                break;
                            case "CLOSED":
                                dot = <InfoCircleOutlined style={{ fontSize: '16px', color: '#52C31A'}}/>
                                children = <>{t('tickets.details.timeline-area.status.message')}<br/>{t('tickets.details.timeline-area.status.closed')}</>
                                break;
                            default:
                                break;
                        }
                        break;  
                    }
                    case "ticket_assigned_to_manu": {
                        dot = <UserSwitchOutlined style={{fontSize: '16px'}}/>
                        const valueOldDisplay = item.value_old.trim() === "" ? "-" : item.value_old;
                        children = <> {valueOldDisplay} <br/> ➜ <br/> {item.value_new}</>
                        break;
                    }
                    default:
                        return null;
                        
                }

                return {
                    label, 
                    children,
                    dot
                }
            });
            transformedTimeline.filter(item => item !== null);
            //transformedTimeline.reverse();
            return transformedTimeline;
        } else {
            return [];
        }
    }

    const scrollToBottom = () => {
        const container = timelineContainerRef.current;
        if (container) {
            if (navigator.userAgent.toLowerCase().includes('firefox')) {
                container.scrollIntoView({
                    block: "end",
                    behavior: "smooth"
                });
            } else {
                container.scrollIntoView({
                    block: "end",
                    
            })
        }
        }
    };


    useEffect(() => {
        if (ticket.hasOwnProperty('id')) {
            loadEventData();
        }
        // eslint-disable-next-line
    }, [ticket])

    useEffect(() => {
        let timelineItems= [];
        if (serviceHistoryTimeline) {
            timelineItems = buildTimeline();
            timelineItems.unshift({
                label: dayjs(ticket.created_at).format(getDateFormatWithoutTime(i18n.language)),
                children: t('tickets.details.timeline-area.ticket-created'),
                dot: <AlertOutlined style={{ fontSize: '16px'}}/>,
                color: '#F9AC14'
            })
            /* if (ticket.closed_at !== "NULL" && ticket.closed_at !== undefined) {
                timelineItems.push({
                    label: dayjs(ticket.closed_at).format(getDateFormatWithoutTime(i18n.language)),
                    children: t('tickets.details.timeline-area.ticket-closed'),
                    dot: <CheckCircleOutlined style={{ fontSize: '16px'}}/>,
                    color: 'green'
                })
            } */
        }
        setFinalTimeline(timelineItems);
    }, [serviceHistoryTimeline])

    useEffect(() => {
        setTimeout(scrollToBottom, 500);
    }, [finalTimeline])



    return (
        <div style={{maxHeight: "82vh", overflowY: 'auto'}}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="82vh" autoHide>
                <div ref={timelineContainerRef}>
                    <Timeline
                        className="no-border-radius"
                        mode="left"
                        style={{paddingRight: "8px", paddingTop: "4px", maxWidth: "30vh"}}
                        items={finalTimeline}
                    />
                </div>
            </Scrollbars>
        </div>
    )

}

export default TimelineArea