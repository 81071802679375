import { Card, Row, Col, Button, Input, Space, Form, Tooltip, Tag, notification } from 'antd';
import {useTranslation} from "react-i18next";
import React, { useState, useEffect } from 'react';
import { EditOutlined, SaveOutlined, CloseOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { changeTicketInfo } from '../../../utils/RequestBuilder';
import Scrollbars from 'react-custom-scrollbars-2';

function MainAreaCard({ticket, auth, hasManufacturerRole}) {

    const [ ticketData, setTicketData ] = useState(ticket);
    const [ editableForCustomer, setEditableForCustomer] = useState(false);
    const [ editableForManufacturer, setEditableForManufacturer] = useState(false);
    const [ form ] = Form.useForm();
    const { t, i18n } = useTranslation();
    const { TextArea } = Input;

    const titleSpan = 6
    const contentSpan = 18


    useEffect(() => {
        setTicketData(ticket)
    }, [ticket])

    useEffect(() => {
        form.resetFields()
    }, [ticketData])

   //Show Ticket Information Update Alert
   const [api, contextHolder] = notification.useNotification();
   const openNotification = (placement, status, desc, title, errorCodeString) => {
       api[status]({
         message: title,
         description: 
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
       });
   };
   function showAlert(response) {
       let desc = "";
       let title = "";
       let errorCode = "";
       if (response === 200) {
           title = t('tickets.details.main-area.update-message.title.success');
           desc = t('tickets.details.main-area.update-message.body.success');
           openNotification('topRight', 'success', desc, title, errorCode)
       } else {
           title = t('tickets.details.main-area.update-message.title.error');
           desc = t('tickets.details.main-area.update-message.body.error');
           errorCode = '[' + t('tickets.details.main-area.update-message.status-code') + ': ' + response + ']'
           openNotification('topRight', 'error', desc, title, errorCode)
       }
   }


   //Update Ticket information in database
   async function saveFormDataForCustomer(auth, path, descValue, titleValue, id) {
        if (descValue === "") {descValue = " "}
        if (titleValue === "") {titleValue = " "}
        const attributesArray = [
            {attributeName: "description", attributeValue: descValue},
            {attributeName: "title", attributeValue: titleValue}
        ]
        try {
            const result = await changeTicketInfo(auth, path, attributesArray, id);
            showAlert(result);
        } catch (error) {
            console.error(error);
        }
    }

    //Update Ticket information in database
    async function saveFormDataForManufacturer(auth, path, troubleValue, id) {
        if (troubleValue === "") {troubleValue = " "}
        const attributesArray = [
            {attributeName: "troubleshooting", attributeValue: troubleValue},
        ]
        try {
            const result = await changeTicketInfo(auth, path, attributesArray, id);
            showAlert(result);
        } catch (error) {
            console.error(error);
        }
    }

    const renderTag = (hasService, t) => {
        if (!hasManufacturerRole) {
            if (hasService) {
                return (
                    <Tooltip title={t('tickets.overview.tooltip.access')}>
                        <Tag color="green"><EyeOutlined /></Tag>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={t('tickets.overview.tooltip.no-access')}>
                        <Tag><EyeInvisibleOutlined /></Tag>
                    </Tooltip>
                )
            }
        } else return (<></>);
    }



    return (
        <>
            {contextHolder}
            <Form
                form={form}
                name= "basic"
                initialValues={{
                    description: ticketData.description,
                    troubleshooting: ticketData.troubleshooting,
                    title: ticketData.title
                }}
            >
                <Scrollbars
                    autohide="true"
                    style={{minHeight: "26vh"}}
                >
                    <Card
                        className="tickets-details-card"
                        style={{maxHeight: "25vh"}}
                        title={
                            <div>       
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Col
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            {/* {renderTag(ticket.has_service, t)} */}
                                            <Form.Item
                                                name="title"
                                                style={{
                                                    marginBottom: '0px'
                                                }}
                                            >
                                                <TextArea 
                                                    style={{
                                                        resize: 'none',
                                                        fontWeight: 'bold',
                                                        minWidth: '600px',
                                                        padding: '4px 0px 4px 4px',
                                                        alignSelf: 'center'}}
                                                    allowClear={editableForCustomer}
                                                    bordered={editableForCustomer}
                                                    readOnly={!editableForCustomer}
                                                    autoSize={true} 
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    {/* Area to show for the customer */}
                                    {!hasManufacturerRole && (
                                        <Col>
                                            {/*Show edit button if edit mode is not already entered */}
                                            {(!editableForCustomer) ? (
                                                <Button
                                                    type="primary"
                                                    onClick={() => setEditableForCustomer(!editableForCustomer)}
                                                >
                                                    <EditOutlined /> {t('tickets.details.main-area.edit')}
                                                </Button>
                                            ) : (
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            const formValues = form.getFieldsValue();
                                                            const descValue = formValues.description;
                                                            const troubleValue = formValues.troubleshooting;
                                                            const titleValue = formValues.title;
                                                            setTicketData({
                                                                ...ticketData,
                                                                description: descValue,
                                                                troubleshooting: troubleValue,
                                                                title: titleValue
                                                            })
                                                            saveFormDataForCustomer(auth, "/tickets/", descValue, titleValue, ticket.id);

                                                            setEditableForCustomer(!editableForCustomer)}
                                                        }
                                                    >
                                                        <SaveOutlined /> {t('tickets.details.main-area.save')}
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            form.resetFields();
                                                            setEditableForCustomer(!editableForCustomer)}
                                                        }
                                                    >
                                                        <CloseOutlined /> {t('tickets.details.main-area.cancel')}
                                                    </Button>
                                                </ Space>
                                            )}
                                        </Col>
                                    )}
                                    {/* Area to show for the manufacturer */}
                                    {hasManufacturerRole && (
                                        <Col>
                                            {/*Show edit button if edit mode is not already entered */}
                                            {(!editableForManufacturer) ? (
                                                <Button
                                                    type="primary"
                                                    onClick={() => setEditableForManufacturer(!editableForManufacturer)}
                                                >
                                                    <EditOutlined /> {t('tickets.details.main-area.edit')}
                                                </Button>
                                            ) : (
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            const formValues = form.getFieldsValue();
                                                            const descValue = formValues.description;
                                                            const troubleValue = formValues.troubleshooting;
                                                            const titleValue = formValues.title;
                                                            setTicketData({
                                                                ...ticketData,
                                                                description: descValue,
                                                                troubleshooting: troubleValue,
                                                                title: titleValue
                                                            })
                                                            saveFormDataForManufacturer(auth, "/customers/tickets/", troubleValue, ticket.id);

                                                            setEditableForManufacturer(!editableForManufacturer)}
                                                        }
                                                    >
                                                        <SaveOutlined /> {t('tickets.details.main-area.save')}
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            form.resetFields();
                                                            setEditableForManufacturer(!editableForManufacturer)}
                                                        }
                                                    >
                                                        <CloseOutlined /> {t('tickets.details.main-area.cancel')}
                                                    </Button>
                                                </ Space>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        }
                    >  
                        <div>
                            <Row key='1'>
                                <Col span={titleSpan}>
                                    {t('tickets.details.main-area.description')}
                                </Col>
                                <Col span={contentSpan}>
                                    <Form.Item
                                        name="description"
                                    >
                                        <TextArea 
                                            style={{
                                                resize: 'none',
                                                padding: '0 0 0 0'}}
                                            allowClear={editableForCustomer}
                                            bordered={editableForCustomer}
                                            readOnly={!editableForCustomer}
                                            autoSize={true} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row key='2'>
                                <Col span={titleSpan}>
                                    {t('tickets.details.main-area.solution')}
                                </Col>
                                <Col span ={contentSpan}>
                                    <Form.Item
                                        name="troubleshooting">
                                        <TextArea
                                            style={{
                                                resize: 'none',
                                                padding: '0 0 0 0'
                                            }} 
                                            allowClear={editableForManufacturer}
                                            bordered={editableForManufacturer}
                                            readOnly={!editableForManufacturer}
                                            autoSize={true} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> 
                        </div>
                    </Card>
                </Scrollbars>
            </Form>
        </>
    )
}

export default MainAreaCard;
