import {useTranslation} from "react-i18next";
import {WarningOutlined} from "@ant-design/icons";

function AccessDenied() {

    const { t } = useTranslation();

    return (
        <div
            style={{
                marginTop: 25,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 18
            }}
        >
            <WarningOutlined style={{fontSize: 28, color: "#e87722"}} />
            <p>
                {t("access.denied.info")}
            </p>
            <p>
                {t("access.denied.contact")}
            </p>
        </div>
    )
}

export default AccessDenied;