import {Avatar, Form, Select} from "antd";
import {getAvatarColor} from "../../../utils/utils";
import {useTranslation} from "react-i18next";

const { Option } = Select;

function PersonFilter({value, onChange, users}) {

    const { t } = useTranslation();
    return (

            <Select
                value={value}
                showSearch
                allowClear
                width='160'
                placeholder={t('requests.overview.filter.person')}
                optionFilterProp="children"
                onChange={onChange}
                style={{minWidth: "150px"}}
                filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
            >
                {
                    (users || []).map((user) => {
                        return(
                            <Option key={user.id} value={user.id} searchterm={user.name}>
                                <Avatar style={{ backgroundColor: getAvatarColor(user.signature)}}>
                                    {user.signature}
                                </Avatar>  {user.name}
                            </Option>);
                    })
                }
            </Select>
    )
}

export default PersonFilter