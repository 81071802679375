import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Form, Modal, Select, notification } from "antd";
import { payloadApi, getApi } from "../../../utils/RequestBuilder";
import { CustomerServiceOutlined, CarOutlined, LaptopOutlined, UserOutlined } from "@ant-design/icons";

function AddNotificationModal({auth, isOpen, userList, customerList, assetTypesList, modalIsOpenCallback, reloadCallback, reload}) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [users, setUsers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [selectedAssetType, setSelectedAssetType]= useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [services, setServices] = useState();
    const [modules, setModules] = useState ([]);

    useEffect(() =>  {
        setUsers(userList);
        setCustomers(customerList);
        setAssetTypes(assetTypesList);
    }, [userList, customerList, assetTypesList])

    const handleOk = async () => {
        await form.validateFields()
            const formValues = form.getFieldsValue();
            let payload = createPayload(formValues)
            // handleCreation(auth, createPayload(formValues))
            const addResult = await handleCreation(auth, payload)
            modalIsOpenCallback(false)
            form.resetFields();
            if (addResult === 200) {
                let triggerCounter = reload;
                triggerCounter++;
                reloadCallback(triggerCounter);
            }
        
    };

    //Fetch Services from server
    async function loadServicesForAssetType() {
        if (selectedAssetType !== null) {
            try {
                const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types/" + selectedAssetType + "/details")
                setServices(jsonData);
            } catch (error) {
                console.log(error)
            }
        }
    }

    //Show Criteria Create Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };


    function createPayload(formData) {
        return {
            "user_key": formData.users,
            "customers": formData.customers,
            "asset_type": formData.asset_types,
            "service": formData.services,
            "modules": formData.modules
        }
    }

    async function handleCreation(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/criteria",
                "POST",
                payload
            );
            showCreateAlert(result);
            if (result === 200) {
                let triggerCounter = reload;
                triggerCounter++;
                reloadCallback(triggerCounter);
            }
            return result.status
        } catch (error) {
            console.log(error)
        }
    }

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response.status === 200) {
            title = t('administration.notifications.form.create.criteria.create-message.title.success');
            desc = t('administration.notifications.form.create.criteria.create-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('administration.notifications.form.create.criteria.create-message.title.error');
            desc = t('administration.notifications.form.create.criteria.create-message.body.error');
            errorCode = '[' + t('administration.notifications.form.create.criteria.create-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    const onFinish = (values) => {
        form.resetFields();
    }

    const handleCancel = () => {
        modalIsOpenCallback(false)
        form.resetFields();
    };

    useEffect(() => {
        loadServicesForAssetType();
        // eslint-disable-next-line
    }, [selectedAssetType])

    useEffect(() => {
        let modulesOptions = [];
        switch (selectedService) {
            case "GUIDED":
                modulesOptions = services.service_guided.modules;
                break;
            case "ONSITE":
                modulesOptions = services.service_onsite.modules;
                break;
            case "REMOTE":
                modulesOptions = services.service_remote.modules;
                break;
            case "PERSONAL":
                modulesOptions = services.service_personal.modules;
                break;
            default:
                modulesOptions = [];
                break;
        }
        setModules(modulesOptions);
    }, [selectedService, services])


    return (
        <>
            {contextHolder}
            <Modal
                title={t('administration.notifications.form.add')}
                open={isOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                >
                    <Form.Item
                        label={t('administration.mail-notifications.users')}
                        name="users" 
                        rules={[{
                            required: true,
                            message: t('administration.mail-notifications.user-message')
                        }]}
                    >
                        <Select  
                            placeholder={t('administration.mail-notifications.placeholder.users')}
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}>
                                {users.map(user => (
                                    <Select.Option key={user.id} value={user.id} searchterm={user.name}>
                                        {user.name}
                                    </Select.Option>
                                ))}
                        </Select> 
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.customers')}
                        name="customers"
                    >
                        <Select  
                            placeholder={t('administration.mail-notifications.placeholder.customers')} 
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}>
                                {customers.map(customer => (
                                    <Select.Option key={customer.id} value={customer.id} searchterm={customer.name}>
                                        {customer.name}
                                    </Select.Option>
                                ))}
                        </Select> 
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.asset-types')}
                        name="asset_types"
                    >
                        <Select  
                            placeholder={t('administration.mail-notifications.placeholder.asset-types')}
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            onChange={(value) => setSelectedAssetType(value)}
                            options={
                                assetTypes.map((item) => ({
                                    label: item.product_line.name,
                                    options: item.asset_types.map(asset => ({
                                            label: asset.name,
                                            value: asset.id,
                                            searchterm: asset.name
                                    })),
                                }))
                            } 
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.services')}
                        name="services"
                    >
                        <Select
                            placeholder={t('administration.mail-notifications.placeholder.services')}
                            onChange={(value) => setSelectedService(value)}  
                        >
                            {services && Object.keys(services).length > 0 && (
                                <>
                                    <Select.Option key={"guided"} value={"GUIDED"} disabled={!services.service_guided.available}>
                                        <div 
                                            style={{ display: "flex", alignItems: "center"}}
                                        >
                                            <CustomerServiceOutlined /><span style={{marginLeft: "4px"}}>{t('administration.service-types.guided')}</span>
                                        </div>
                                    </Select.Option>
                                    <Select.Option key={"onsite"} value={"ONSITE"} disabled={!services.service_onsite.available}>
                                        <div 
                                            style={{ display: "flex", alignItems: "center"}}
                                        >
                                            <CarOutlined /><span style={{marginLeft: "4px"}}>{t('administration.service-types.onsite')}</span>
                                        </div>
                                    </Select.Option>
                                    <Select.Option key={"remote"} value={"REMOTE"} disabled={!services.service_remote.available}>
                                        <div 
                                            style={{ display: "flex", alignItems: "center"}}
                                        >
                                            <LaptopOutlined /><span style={{marginLeft: "4px"}}>{t('administration.service-types.remote')}</span>
                                        </div>
                                    </Select.Option>
                                    <Select.Option key={"personal"} value={"PERSONAL"} disabled={!services.service_personal.available}>
                                        <div 
                                            style={{ display: "flex", alignItems: "center"}}
                                        >
                                            <UserOutlined /><span style={{marginLeft: "4px"}}>{t('administration.service-types.personal')}</span>
                                        </div>
                                    </Select.Option>
                                </>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.modules')}
                        name="modules"
                    >
                        <Select
                            placeholder={t('administration.mail-notifications.placeholder.modules')}
                            mode="multiple" 
                            options={
                                modules.map((item) => ({
                                    label: item.name,
                                    key: item.id,
                                    value: item.id
                                  
                                }))
                            } 
                        >      
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AddNotificationModal