import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, notification } from 'antd';
import { useEffect, useState } from "react";
import { createNewTicket } from '../../utils/RequestBuilder';


function AddWorkstationModal({isOpen, auth, callbackFct, customerId}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            const WsInformationArray = createWsArray(formValues);
            handleWsCreation(auth, WsInformationArray)
            setIsModalOpen(false)
            form.resetFields();
        })
    };

    function createWsArray(formData) {
        const WsInformationArray = [
            {attributeName: "name", attributeValue: formData.title},
        ]
        return WsInformationArray
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response == 200) {
            title = t('assets.forms.create.workstation.create-message.title.success');
            desc = t('assets.forms.create.workstation.create-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            
            title = t('assets.forms.create.workstation.create-message.title.error');
            desc = t('assets.forms.create.workstation.create-message.body.error');
            errorCode = '[' + t('tickets.forms.create.create-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    async function handleWsCreation(auth, attributesArray) {
        try {
            const result = await createNewTicket(auth, "/customers/" + customerId +"/workstations", attributesArray);
            showCreateAlert(result);
            if (result === 200) {callbackFct();}
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }

    const onFinish = (values) => {
    }
     
    const handleCancel = () => {
        setIsModalOpen(false)
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('assets.forms.create.workstation.title')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    
                >
                    <Form.Item
                         label={t('assets.forms.create.workstation.label')}
                         name="title"
                         rules={[{
                             required: true,
                             message: t('assets.forms.create.workstation.label') + ' ' + t('assets.forms.error-message')
                         }]} 
                    >
                        <Input placeholder={t('assets.forms.create.workstation.placeholder')}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AddWorkstationModal;
