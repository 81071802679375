import { useTranslation } from 'react-i18next';
import { Modal, notification, Space, Radio } from 'antd';
import {useEffect, useState} from "react";
import { changeAssetWs } from '../../utils/RequestBuilder';




function ChangeAssetAssignmentModal({isOpen, auth, assetList, id, setForceRerender, customer}) {

    const { t } = useTranslation();
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ reRender, setReRender] = useState(0);
    const [ wsSelection, setWsSelection] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    
    function extractWorkstations(assetList) {
        assetList.sort((a, b) => a.position - b.position)
        const workstations = [];
        assetList.forEach(ws => {
            if (ws.assets) {
                workstations.push({
                    id: ws.id,
                    title: ws.name,
                    key: ws.id
                    //assets: asset.assets
                });
            }
        });
        return workstations;
    }

    function findWsId(assetList, id) {
        for (const parent of assetList) {
            for (const asset of parent.assets) {
                if (asset.id === id) {
                    return parent.id;
                }
            }
        }
        return null
    }

    useEffect(() => {
        const workstations = extractWorkstations(assetList);
        setWsSelection(workstations)
        const currentAssetWs = findWsId(assetList, id);
        if (currentAssetWs) {setSelectedOption(currentAssetWs)}
    }, [assetList, isOpen, id]);


    //Show Ticket Create Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

   function showCreateAlert(response) {
       let desc = "";
       let title = "";
       let errorCode = "";
       if (response == 200) {
           title = t('assets.forms.assignment.assignment-message.title.success');
           desc = t('assets.forms.assignment.assignment-message.body.success');
           openNotification('topRight', 'success', desc, title, errorCode)
       } else {
           title = t('assets.forms.assignment.assignment-message.title.error');
           desc = t('assets.forms.assignment.assignment-message.body.error');
           errorCode = '[' + t('assets.forms.assignment.assignment-message.status-code') + ': ' + response + ']'
           openNotification('topRight', 'error', desc, title, errorCode)
       }
   }

   

    const handleOk = () => {
        setIsModalOpen(false)
        handleAssignmentChange();
        let renderCounter = reRender;
        renderCounter += 1;
        setReRender(renderCounter);
        setForceRerender(renderCounter); 
    }

    async function handleAssignmentChange() {
        let result;
        try {
            result = await changeAssetWs(auth, "/customers/" + customer + "/assets/" + id, selectedOption )
            showCreateAlert(result);
            //if (result === 200) {callbackFct();}
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
       }
    

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    useEffect(() => {
        setIsModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    const onChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <>
            {contextHolder}
            <Modal
                //className="tickets-modal-create"
                title={<>{t('assets.overview.change-asset-assignment-title')}{" "}<span className="ant-form-text" style={{opacity: 0.4}}>{"| "}{t('assets.overview.choose-workstation')}</span></>}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                //afterOpenChange={form.resetFields()}
            >   
                <br/>
                
                <Radio.Group onChange={onChange} value={selectedOption} >
                    <Space direction="vertical">
                        {wsSelection.map((option, index) => (
                        <Radio key={index} value={option.id}>{option.title}</Radio>
                        ))}
                    </Space>
                </Radio.Group>
                
            </Modal>
        </>
    )

}

export default ChangeAssetAssignmentModal;