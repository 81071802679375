import {Form, Select} from "antd";

function EquipmentFilter({onChange, options, value}) {

    return(
        <Form>
            <Select
                showSearch
                allowClear
                placeholder="Select Equipment"
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options}
                value={value}
                style={{minWidth: "150px"}}
            />
        </Form>
    )
}

export default EquipmentFilter