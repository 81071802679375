
function ResultArea() {

    return (
        <div>
            Dies ist der Ergebnisbereich.
        </div>
    );
}

export default ResultArea;
