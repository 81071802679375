import React, {useEffect, useState} from 'react';
import { Tree } from 'antd';
import { transformToSelectablesUtils } from '../../utils/utils';

const NavMenu = ({ setSelection, assetList, menuItemKey, hashValue }) => {

    const [gData, setGData] = useState(sortObject(transformData(assetList)))
    const [selectedKeys, setSelectedKeys] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([generateExpandedKeys(transformData(assetList))])
    const [selectables, setSelectables] = useState(transformToSelectablesUtils(assetList))

    // Generate Menu Tree Data
    function transformData(inputData) {
        const transformedData = inputData.map((workstation) => {
          const children = workstation.assets.map((asset) => {
            return {
              title: asset.name,
              key: `${workstation.position.toString().padStart(3, '0')}.${asset.position.toString().padStart(1, '0')}`,
            }
          })
      
          return {
            title: workstation.name,
            key: workstation.position.toString().padStart(3, '0'),
            children: children,
          }
        })

        /* const sortedData = transformedData.sort((a, b) => {
            return a.key.localeCompare(b.key);
        }); */
        /* const sortedData = transformedData.sort((a, b) => {
            const keyA = parseInt(a.key.split('.')[0]);
            const keyB = parseInt(b.key.split('.')[0]);
            return keyA - keyB;
        }); */
          
        return transformedData
    }

    function sortObject(obj) {
        obj.sort((a, b) => parseInt(a.key) - parseInt(b.key))

        for (let i = 0; i < obj.length; i++) {
            if (obj[i].children && obj[i].children.length > 0) {
                obj[i].children.sort((a, b) => parseInt(a.key.split('.')[1]) - parseInt(b.key.split('.')[1]))
            }
        }
    
        return obj
    }

    function generateExpandedKeys(inputData) {
        return inputData.map((workstation) => workstation.key)
    }

    useEffect(() => {
        setExpandedKeys(generateExpandedKeys(transformData(assetList)))
        setSelectables(transformToSelectablesUtils(assetList))
    }, [assetList])

    useEffect(() => {
        setGData(sortObject((transformData(assetList))))
        // eslint-disable-next-line
    }, [expandedKeys])

    const onSelect = (info) => {
        if (info.length > 0) {
            setSelection(
                info[0], 
                selectables[info[0]].name, 
                selectables[info[0]].type,
                selectables[info[0]].internal_identifier,
                selectables[info[0]].id,
                selectables[info[0]].position,
                selectables[info[0]].type,
                selectables[info[0]].type_id,
                selectables[info[0]].manufacturer,
                selectables[info[0]].equipment_number)
            setSelectedKeys([info[0]])
        }
    }

    function findIdentifierById(id, data) {
        for (const key in data) {
            if (typeof data[key] === 'object' && data[key] !== null) {
                if (data[key].id === id) {
                    return key
                }
            }
        }
        // Falls keine Übereinstimmung gefunden wurde, gib null zurück
        return null
    }

    useEffect(() => {
        if (selectables && Object.keys(selectables).length > 0) {
            if (hashValue !== "" && hashValue !== undefined) {
                const identifier = findIdentifierById(hashValue, selectables)
                setSelection(
                    identifier, 
                    selectables[identifier].name, 
                    selectables[identifier].type,
                    selectables[identifier].internal_identifier,
                    selectables[identifier].id,
                    selectables[identifier].position,
                    selectables[identifier].type,
                    selectables[identifier].type_id,
                    selectables[identifier].manufacturer,
                    selectables[identifier].equipment_number
                )
                setSelectedKeys([identifier])
            } else {
                setSelection(
                    menuItemKey, 
                    selectables[menuItemKey].name, 
                    selectables[menuItemKey].type,
                    selectables[menuItemKey].internal_identifier,
                    selectables[menuItemKey].id,
                    selectables[menuItemKey].position,
                    selectables[menuItemKey].type,
                    selectables[menuItemKey].type_id,
                    selectables[menuItemKey].manufacturer,
                    selectables[menuItemKey].equipment_number
                )
                setSelectedKeys(menuItemKey)
            }
        }
    }, [selectables])


    useEffect(() => {
    }, [selectedKeys])

    useEffect(() => {
        if (hashValue !== "" && hashValue !== undefined) {
            const identifier = findIdentifierById(hashValue, selectables)
            setSelection(
                identifier, 
                selectables[identifier].name, 
                selectables[identifier].type,
                selectables[identifier].internal_identifier,
                selectables[identifier].id,
                selectables[identifier].position,
                selectables[identifier].type,
                selectables[identifier].type_id,
                selectables[identifier].manufacturer,
                selectables[identifier].equipment_number
            )
            setSelectedKeys([identifier])
        }
        // eslint-disable-next-line
    }, [hashValue])


    return (
        <Tree
            defaultSelectedKeys={menuItemKey}
            expandedKeys={expandedKeys}
            // onDrop={onDrop}
            onSelect={onSelect}
            treeData={gData}
            selectedKeys={selectedKeys}
        />
    )

    /*
    // Generate Menu Tree Selectables
    function transformToSelectables(originalData) {
        const selectables = {};

        originalData.forEach((workstation) => {
          const workstationKey = workstation.position.toString().padStart(3, '0');
          const workstationName = workstation.name;
          const workstationInternalId = workstation.internal_identifier;
          const workstationId = workstation.id;
          const workstationPosition = workstation.position;

          selectables[workstationKey] = {
            type: "workstation",
            name: workstationName,
            internal_identifier: workstationInternalId,
            id: workstationId,
            position: workstationPosition,
          };

          if (workstation.assets) {
            workstation.assets.forEach((asset) => {
                const assetKey = `${workstationKey}.${asset.position.toString().padStart(1, '0')}`;
                const assetName = asset.name;
                const assetInternalId = asset.internal_identifier;
                const assetId = asset.id;
                const assetPosition = asset.position;
                const assetType = asset.type;
                const assetManufacturer = asset.manufacturer;
                const assetNo = asset.equipment_number;

                selectables[assetKey] = {
                    name: assetName,
                    internal_identifier: assetInternalId,
                    id: assetId,
                    position: assetPosition,
                    type: assetType,
                    manufacturer: assetManufacturer,
                    equipment_number: assetNo
                };
            });
          }
        });
        return selectables;
      }

      // Drag and Drop
    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...gData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });
        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 &&
            // Has children
            info.node.props.expanded &&
            // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // item to the tail of the children
            });
        } else {
            let ar = [];
            let i;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }
        setGData(data);
        // navigate('/assets/1')
    };

    function sortArrayByKeys(arr) {
        arr.forEach((item) => {
            if (item.children) {
                item.children = sortArrayByKeys(item.children);
            }
        });
        return arr.sort((a, b) => a.key.localeCompare(b.key));
    }
     */
};

export default NavMenu;
