import {Link, Outlet} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Menu} from "antd";

function WebshopTemplate() {

    const { t } = useTranslation();

    const menuItems = [
        { label: <Link to={`/webshop/search`}>{t('webshop.menu.search')}</Link> },
        { label: <Link to={`/webshop/shopping_card`}>{t('webshop.menu.shopping_card')}</Link> },
        { label: <Link to={`/webshop/orders`}>{t('webshop.menu.orders')}</Link> },
    ];

    return (
        <div>
            <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={['2']}
                items={menuItems}
            />
            <Outlet />
        </div>
    );
}

export default WebshopTemplate;
