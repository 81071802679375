import SearchBar from "../../components/Webshop/SearchBar";
import ResultArea from "../../components/Webshop/ResultArea";

function Search() {

    return (
        <div>
            <SearchBar />
            <ResultArea />
        </div>
    );
}

export default Search;
